import { __assign, __awaiter, __generator } from "tslib";
import { getRegionInfo } from "@aws-sdk/config-resolver";
var regionHash = {};
var partitionHash = {
    aws: {
        regions: [
            "af-south-1",
            "ap-east-1",
            "ap-northeast-1",
            "ap-northeast-2",
            "ap-northeast-3",
            "ap-south-1",
            "ap-southeast-1",
            "ap-southeast-2",
            "ap-southeast-3",
            "ca-central-1",
            "eu-central-1",
            "eu-north-1",
            "eu-south-1",
            "eu-west-1",
            "eu-west-2",
            "eu-west-3",
            "me-south-1",
            "sa-east-1",
            "us-east-1",
            "us-east-2",
            "us-west-1",
            "us-west-2",
        ],
        regionRegex: "^(us|eu|ap|sa|ca|me|af)\\-\\w+\\-\\d+$",
        variants: [
            {
                hostname: "geo.{region}.amazonaws.com",
                tags: [],
            },
            {
                hostname: "geo-fips.{region}.amazonaws.com",
                tags: ["fips"],
            },
            {
                hostname: "geo-fips.{region}.api.aws",
                tags: ["dualstack", "fips"],
            },
            {
                hostname: "geo.{region}.api.aws",
                tags: ["dualstack"],
            },
        ],
    },
    "aws-cn": {
        regions: ["cn-north-1", "cn-northwest-1"],
        regionRegex: "^cn\\-\\w+\\-\\d+$",
        variants: [
            {
                hostname: "geo.{region}.amazonaws.com.cn",
                tags: [],
            },
            {
                hostname: "geo-fips.{region}.amazonaws.com.cn",
                tags: ["fips"],
            },
            {
                hostname: "geo-fips.{region}.api.amazonwebservices.com.cn",
                tags: ["dualstack", "fips"],
            },
            {
                hostname: "geo.{region}.api.amazonwebservices.com.cn",
                tags: ["dualstack"],
            },
        ],
    },
    "aws-iso": {
        regions: ["us-iso-east-1", "us-iso-west-1"],
        regionRegex: "^us\\-iso\\-\\w+\\-\\d+$",
        variants: [
            {
                hostname: "geo.{region}.c2s.ic.gov",
                tags: [],
            },
            {
                hostname: "geo-fips.{region}.c2s.ic.gov",
                tags: ["fips"],
            },
        ],
    },
    "aws-iso-b": {
        regions: ["us-isob-east-1"],
        regionRegex: "^us\\-isob\\-\\w+\\-\\d+$",
        variants: [
            {
                hostname: "geo.{region}.sc2s.sgov.gov",
                tags: [],
            },
            {
                hostname: "geo-fips.{region}.sc2s.sgov.gov",
                tags: ["fips"],
            },
        ],
    },
    "aws-us-gov": {
        regions: ["us-gov-east-1", "us-gov-west-1"],
        regionRegex: "^us\\-gov\\-\\w+\\-\\d+$",
        variants: [
            {
                hostname: "geo.{region}.amazonaws.com",
                tags: [],
            },
            {
                hostname: "geo-fips.{region}.amazonaws.com",
                tags: ["fips"],
            },
            {
                hostname: "geo-fips.{region}.api.aws",
                tags: ["dualstack", "fips"],
            },
            {
                hostname: "geo.{region}.api.aws",
                tags: ["dualstack"],
            },
        ],
    },
};
export var defaultRegionInfoProvider = function (region, options) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, getRegionInfo(region, __assign(__assign({}, options), { signingService: "geo", regionHash: regionHash, partitionHash: partitionHash }))];
    });
}); };
