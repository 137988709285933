import { __assign } from "tslib";
import { SENSITIVE_STRING } from "@aws-sdk/smithy-client";
export var AssociateTrackerConsumerRequest;
(function (AssociateTrackerConsumerRequest) {
    AssociateTrackerConsumerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AssociateTrackerConsumerRequest || (AssociateTrackerConsumerRequest = {}));
export var AssociateTrackerConsumerResponse;
(function (AssociateTrackerConsumerResponse) {
    AssociateTrackerConsumerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AssociateTrackerConsumerResponse || (AssociateTrackerConsumerResponse = {}));
export var ValidationExceptionField;
(function (ValidationExceptionField) {
    ValidationExceptionField.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ValidationExceptionField || (ValidationExceptionField = {}));
export var BatchDeleteDevicePositionHistoryRequest;
(function (BatchDeleteDevicePositionHistoryRequest) {
    BatchDeleteDevicePositionHistoryRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchDeleteDevicePositionHistoryRequest || (BatchDeleteDevicePositionHistoryRequest = {}));
export var BatchItemError;
(function (BatchItemError) {
    BatchItemError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchItemError || (BatchItemError = {}));
export var BatchDeleteDevicePositionHistoryError;
(function (BatchDeleteDevicePositionHistoryError) {
    BatchDeleteDevicePositionHistoryError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchDeleteDevicePositionHistoryError || (BatchDeleteDevicePositionHistoryError = {}));
export var BatchDeleteDevicePositionHistoryResponse;
(function (BatchDeleteDevicePositionHistoryResponse) {
    BatchDeleteDevicePositionHistoryResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchDeleteDevicePositionHistoryResponse || (BatchDeleteDevicePositionHistoryResponse = {}));
export var BatchDeleteGeofenceRequest;
(function (BatchDeleteGeofenceRequest) {
    BatchDeleteGeofenceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchDeleteGeofenceRequest || (BatchDeleteGeofenceRequest = {}));
export var BatchDeleteGeofenceError;
(function (BatchDeleteGeofenceError) {
    BatchDeleteGeofenceError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchDeleteGeofenceError || (BatchDeleteGeofenceError = {}));
export var BatchDeleteGeofenceResponse;
(function (BatchDeleteGeofenceResponse) {
    BatchDeleteGeofenceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchDeleteGeofenceResponse || (BatchDeleteGeofenceResponse = {}));
export var PositionalAccuracy;
(function (PositionalAccuracy) {
    PositionalAccuracy.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PositionalAccuracy || (PositionalAccuracy = {}));
export var DevicePositionUpdate;
(function (DevicePositionUpdate) {
    DevicePositionUpdate.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Position && { Position: SENSITIVE_STRING })), (obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }))); };
})(DevicePositionUpdate || (DevicePositionUpdate = {}));
export var BatchEvaluateGeofencesRequest;
(function (BatchEvaluateGeofencesRequest) {
    BatchEvaluateGeofencesRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.DevicePositionUpdates && {
        DevicePositionUpdates: obj.DevicePositionUpdates.map(function (item) { return DevicePositionUpdate.filterSensitiveLog(item); }),
    }))); };
})(BatchEvaluateGeofencesRequest || (BatchEvaluateGeofencesRequest = {}));
export var BatchEvaluateGeofencesError;
(function (BatchEvaluateGeofencesError) {
    BatchEvaluateGeofencesError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchEvaluateGeofencesError || (BatchEvaluateGeofencesError = {}));
export var BatchEvaluateGeofencesResponse;
(function (BatchEvaluateGeofencesResponse) {
    BatchEvaluateGeofencesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchEvaluateGeofencesResponse || (BatchEvaluateGeofencesResponse = {}));
export var BatchGetDevicePositionRequest;
(function (BatchGetDevicePositionRequest) {
    BatchGetDevicePositionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchGetDevicePositionRequest || (BatchGetDevicePositionRequest = {}));
export var DevicePosition;
(function (DevicePosition) {
    DevicePosition.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Position && { Position: SENSITIVE_STRING })), (obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }))); };
})(DevicePosition || (DevicePosition = {}));
export var BatchGetDevicePositionError;
(function (BatchGetDevicePositionError) {
    BatchGetDevicePositionError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchGetDevicePositionError || (BatchGetDevicePositionError = {}));
export var BatchGetDevicePositionResponse;
(function (BatchGetDevicePositionResponse) {
    BatchGetDevicePositionResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.DevicePositions && {
        DevicePositions: obj.DevicePositions.map(function (item) { return DevicePosition.filterSensitiveLog(item); }),
    }))); };
})(BatchGetDevicePositionResponse || (BatchGetDevicePositionResponse = {}));
export var GeofenceGeometry;
(function (GeofenceGeometry) {
    GeofenceGeometry.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Polygon && { Polygon: obj.Polygon.map(function (item) { return SENSITIVE_STRING; }) }))); };
})(GeofenceGeometry || (GeofenceGeometry = {}));
export var BatchPutGeofenceRequestEntry;
(function (BatchPutGeofenceRequestEntry) {
    BatchPutGeofenceRequestEntry.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Geometry && { Geometry: GeofenceGeometry.filterSensitiveLog(obj.Geometry) }))); };
})(BatchPutGeofenceRequestEntry || (BatchPutGeofenceRequestEntry = {}));
export var BatchPutGeofenceRequest;
(function (BatchPutGeofenceRequest) {
    BatchPutGeofenceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Entries && { Entries: obj.Entries.map(function (item) { return BatchPutGeofenceRequestEntry.filterSensitiveLog(item); }) }))); };
})(BatchPutGeofenceRequest || (BatchPutGeofenceRequest = {}));
export var BatchPutGeofenceError;
(function (BatchPutGeofenceError) {
    BatchPutGeofenceError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchPutGeofenceError || (BatchPutGeofenceError = {}));
export var BatchPutGeofenceSuccess;
(function (BatchPutGeofenceSuccess) {
    BatchPutGeofenceSuccess.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchPutGeofenceSuccess || (BatchPutGeofenceSuccess = {}));
export var BatchPutGeofenceResponse;
(function (BatchPutGeofenceResponse) {
    BatchPutGeofenceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchPutGeofenceResponse || (BatchPutGeofenceResponse = {}));
export var BatchUpdateDevicePositionRequest;
(function (BatchUpdateDevicePositionRequest) {
    BatchUpdateDevicePositionRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Updates && { Updates: obj.Updates.map(function (item) { return DevicePositionUpdate.filterSensitiveLog(item); }) }))); };
})(BatchUpdateDevicePositionRequest || (BatchUpdateDevicePositionRequest = {}));
export var BatchUpdateDevicePositionError;
(function (BatchUpdateDevicePositionError) {
    BatchUpdateDevicePositionError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchUpdateDevicePositionError || (BatchUpdateDevicePositionError = {}));
export var BatchUpdateDevicePositionResponse;
(function (BatchUpdateDevicePositionResponse) {
    BatchUpdateDevicePositionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(BatchUpdateDevicePositionResponse || (BatchUpdateDevicePositionResponse = {}));
export var CalculateRouteCarModeOptions;
(function (CalculateRouteCarModeOptions) {
    CalculateRouteCarModeOptions.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CalculateRouteCarModeOptions || (CalculateRouteCarModeOptions = {}));
export var TruckDimensions;
(function (TruckDimensions) {
    TruckDimensions.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TruckDimensions || (TruckDimensions = {}));
export var TruckWeight;
(function (TruckWeight) {
    TruckWeight.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TruckWeight || (TruckWeight = {}));
export var CalculateRouteTruckModeOptions;
(function (CalculateRouteTruckModeOptions) {
    CalculateRouteTruckModeOptions.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CalculateRouteTruckModeOptions || (CalculateRouteTruckModeOptions = {}));
export var CalculateRouteRequest;
(function (CalculateRouteRequest) {
    CalculateRouteRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.DeparturePosition && { DeparturePosition: SENSITIVE_STRING })), (obj.DestinationPosition && { DestinationPosition: SENSITIVE_STRING })), (obj.WaypointPositions && { WaypointPositions: SENSITIVE_STRING }))); };
})(CalculateRouteRequest || (CalculateRouteRequest = {}));
export var LegGeometry;
(function (LegGeometry) {
    LegGeometry.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.LineString && { LineString: SENSITIVE_STRING }))); };
})(LegGeometry || (LegGeometry = {}));
export var Step;
(function (Step) {
    Step.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.StartPosition && { StartPosition: SENSITIVE_STRING })), (obj.EndPosition && { EndPosition: SENSITIVE_STRING }))); };
})(Step || (Step = {}));
export var Leg;
(function (Leg) {
    Leg.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.StartPosition && { StartPosition: SENSITIVE_STRING })), (obj.EndPosition && { EndPosition: SENSITIVE_STRING })), (obj.Geometry && { Geometry: LegGeometry.filterSensitiveLog(obj.Geometry) })), (obj.Steps && { Steps: obj.Steps.map(function (item) { return Step.filterSensitiveLog(item); }) }))); };
})(Leg || (Leg = {}));
export var CalculateRouteSummary;
(function (CalculateRouteSummary) {
    CalculateRouteSummary.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RouteBBox && { RouteBBox: SENSITIVE_STRING }))); };
})(CalculateRouteSummary || (CalculateRouteSummary = {}));
export var CalculateRouteResponse;
(function (CalculateRouteResponse) {
    CalculateRouteResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Legs && { Legs: obj.Legs.map(function (item) { return Leg.filterSensitiveLog(item); }) })), (obj.Summary && { Summary: CalculateRouteSummary.filterSensitiveLog(obj.Summary) }))); };
})(CalculateRouteResponse || (CalculateRouteResponse = {}));
export var CalculateRouteMatrixRequest;
(function (CalculateRouteMatrixRequest) {
    CalculateRouteMatrixRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.DeparturePositions && { DeparturePositions: SENSITIVE_STRING })), (obj.DestinationPositions && { DestinationPositions: SENSITIVE_STRING }))); };
})(CalculateRouteMatrixRequest || (CalculateRouteMatrixRequest = {}));
export var RouteMatrixEntryError;
(function (RouteMatrixEntryError) {
    RouteMatrixEntryError.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RouteMatrixEntryError || (RouteMatrixEntryError = {}));
export var RouteMatrixEntry;
(function (RouteMatrixEntry) {
    RouteMatrixEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RouteMatrixEntry || (RouteMatrixEntry = {}));
export var CalculateRouteMatrixSummary;
(function (CalculateRouteMatrixSummary) {
    CalculateRouteMatrixSummary.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CalculateRouteMatrixSummary || (CalculateRouteMatrixSummary = {}));
export var CalculateRouteMatrixResponse;
(function (CalculateRouteMatrixResponse) {
    CalculateRouteMatrixResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.SnappedDeparturePositions && { SnappedDeparturePositions: SENSITIVE_STRING })), (obj.SnappedDestinationPositions && { SnappedDestinationPositions: SENSITIVE_STRING }))); };
})(CalculateRouteMatrixResponse || (CalculateRouteMatrixResponse = {}));
export var CreateGeofenceCollectionRequest;
(function (CreateGeofenceCollectionRequest) {
    CreateGeofenceCollectionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateGeofenceCollectionRequest || (CreateGeofenceCollectionRequest = {}));
export var CreateGeofenceCollectionResponse;
(function (CreateGeofenceCollectionResponse) {
    CreateGeofenceCollectionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateGeofenceCollectionResponse || (CreateGeofenceCollectionResponse = {}));
export var MapConfiguration;
(function (MapConfiguration) {
    MapConfiguration.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MapConfiguration || (MapConfiguration = {}));
export var CreateMapRequest;
(function (CreateMapRequest) {
    CreateMapRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateMapRequest || (CreateMapRequest = {}));
export var CreateMapResponse;
(function (CreateMapResponse) {
    CreateMapResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateMapResponse || (CreateMapResponse = {}));
export var DataSourceConfiguration;
(function (DataSourceConfiguration) {
    DataSourceConfiguration.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DataSourceConfiguration || (DataSourceConfiguration = {}));
export var CreatePlaceIndexRequest;
(function (CreatePlaceIndexRequest) {
    CreatePlaceIndexRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreatePlaceIndexRequest || (CreatePlaceIndexRequest = {}));
export var CreatePlaceIndexResponse;
(function (CreatePlaceIndexResponse) {
    CreatePlaceIndexResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreatePlaceIndexResponse || (CreatePlaceIndexResponse = {}));
export var CreateRouteCalculatorRequest;
(function (CreateRouteCalculatorRequest) {
    CreateRouteCalculatorRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateRouteCalculatorRequest || (CreateRouteCalculatorRequest = {}));
export var CreateRouteCalculatorResponse;
(function (CreateRouteCalculatorResponse) {
    CreateRouteCalculatorResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateRouteCalculatorResponse || (CreateRouteCalculatorResponse = {}));
export var CreateTrackerRequest;
(function (CreateTrackerRequest) {
    CreateTrackerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateTrackerRequest || (CreateTrackerRequest = {}));
export var CreateTrackerResponse;
(function (CreateTrackerResponse) {
    CreateTrackerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateTrackerResponse || (CreateTrackerResponse = {}));
export var DeleteGeofenceCollectionRequest;
(function (DeleteGeofenceCollectionRequest) {
    DeleteGeofenceCollectionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteGeofenceCollectionRequest || (DeleteGeofenceCollectionRequest = {}));
export var DeleteGeofenceCollectionResponse;
(function (DeleteGeofenceCollectionResponse) {
    DeleteGeofenceCollectionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteGeofenceCollectionResponse || (DeleteGeofenceCollectionResponse = {}));
export var DeleteMapRequest;
(function (DeleteMapRequest) {
    DeleteMapRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteMapRequest || (DeleteMapRequest = {}));
export var DeleteMapResponse;
(function (DeleteMapResponse) {
    DeleteMapResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteMapResponse || (DeleteMapResponse = {}));
export var DeletePlaceIndexRequest;
(function (DeletePlaceIndexRequest) {
    DeletePlaceIndexRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeletePlaceIndexRequest || (DeletePlaceIndexRequest = {}));
export var DeletePlaceIndexResponse;
(function (DeletePlaceIndexResponse) {
    DeletePlaceIndexResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeletePlaceIndexResponse || (DeletePlaceIndexResponse = {}));
export var DeleteRouteCalculatorRequest;
(function (DeleteRouteCalculatorRequest) {
    DeleteRouteCalculatorRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteRouteCalculatorRequest || (DeleteRouteCalculatorRequest = {}));
export var DeleteRouteCalculatorResponse;
(function (DeleteRouteCalculatorResponse) {
    DeleteRouteCalculatorResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteRouteCalculatorResponse || (DeleteRouteCalculatorResponse = {}));
export var DeleteTrackerRequest;
(function (DeleteTrackerRequest) {
    DeleteTrackerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteTrackerRequest || (DeleteTrackerRequest = {}));
export var DeleteTrackerResponse;
(function (DeleteTrackerResponse) {
    DeleteTrackerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteTrackerResponse || (DeleteTrackerResponse = {}));
export var DescribeGeofenceCollectionRequest;
(function (DescribeGeofenceCollectionRequest) {
    DescribeGeofenceCollectionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeGeofenceCollectionRequest || (DescribeGeofenceCollectionRequest = {}));
export var DescribeGeofenceCollectionResponse;
(function (DescribeGeofenceCollectionResponse) {
    DescribeGeofenceCollectionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeGeofenceCollectionResponse || (DescribeGeofenceCollectionResponse = {}));
export var DescribeMapRequest;
(function (DescribeMapRequest) {
    DescribeMapRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeMapRequest || (DescribeMapRequest = {}));
export var DescribeMapResponse;
(function (DescribeMapResponse) {
    DescribeMapResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeMapResponse || (DescribeMapResponse = {}));
export var DescribePlaceIndexRequest;
(function (DescribePlaceIndexRequest) {
    DescribePlaceIndexRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribePlaceIndexRequest || (DescribePlaceIndexRequest = {}));
export var DescribePlaceIndexResponse;
(function (DescribePlaceIndexResponse) {
    DescribePlaceIndexResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribePlaceIndexResponse || (DescribePlaceIndexResponse = {}));
export var DescribeRouteCalculatorRequest;
(function (DescribeRouteCalculatorRequest) {
    DescribeRouteCalculatorRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeRouteCalculatorRequest || (DescribeRouteCalculatorRequest = {}));
export var DescribeRouteCalculatorResponse;
(function (DescribeRouteCalculatorResponse) {
    DescribeRouteCalculatorResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeRouteCalculatorResponse || (DescribeRouteCalculatorResponse = {}));
export var DescribeTrackerRequest;
(function (DescribeTrackerRequest) {
    DescribeTrackerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTrackerRequest || (DescribeTrackerRequest = {}));
export var DescribeTrackerResponse;
(function (DescribeTrackerResponse) {
    DescribeTrackerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeTrackerResponse || (DescribeTrackerResponse = {}));
export var DisassociateTrackerConsumerRequest;
(function (DisassociateTrackerConsumerRequest) {
    DisassociateTrackerConsumerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DisassociateTrackerConsumerRequest || (DisassociateTrackerConsumerRequest = {}));
export var DisassociateTrackerConsumerResponse;
(function (DisassociateTrackerConsumerResponse) {
    DisassociateTrackerConsumerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DisassociateTrackerConsumerResponse || (DisassociateTrackerConsumerResponse = {}));
export var ListTagsForResourceRequest;
(function (ListTagsForResourceRequest) {
    ListTagsForResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceRequest || (ListTagsForResourceRequest = {}));
export var ListTagsForResourceResponse;
(function (ListTagsForResourceResponse) {
    ListTagsForResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceResponse || (ListTagsForResourceResponse = {}));
export var TagResourceRequest;
(function (TagResourceRequest) {
    TagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceRequest || (TagResourceRequest = {}));
export var TagResourceResponse;
(function (TagResourceResponse) {
    TagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceResponse || (TagResourceResponse = {}));
export var UntagResourceRequest;
(function (UntagResourceRequest) {
    UntagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceRequest || (UntagResourceRequest = {}));
export var UntagResourceResponse;
(function (UntagResourceResponse) {
    UntagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceResponse || (UntagResourceResponse = {}));
export var GetGeofenceRequest;
(function (GetGeofenceRequest) {
    GetGeofenceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetGeofenceRequest || (GetGeofenceRequest = {}));
export var GetGeofenceResponse;
(function (GetGeofenceResponse) {
    GetGeofenceResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Geometry && { Geometry: GeofenceGeometry.filterSensitiveLog(obj.Geometry) }))); };
})(GetGeofenceResponse || (GetGeofenceResponse = {}));
export var ListGeofenceCollectionsRequest;
(function (ListGeofenceCollectionsRequest) {
    ListGeofenceCollectionsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGeofenceCollectionsRequest || (ListGeofenceCollectionsRequest = {}));
export var ListGeofenceCollectionsResponseEntry;
(function (ListGeofenceCollectionsResponseEntry) {
    ListGeofenceCollectionsResponseEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGeofenceCollectionsResponseEntry || (ListGeofenceCollectionsResponseEntry = {}));
export var ListGeofenceCollectionsResponse;
(function (ListGeofenceCollectionsResponse) {
    ListGeofenceCollectionsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGeofenceCollectionsResponse || (ListGeofenceCollectionsResponse = {}));
export var ListGeofencesRequest;
(function (ListGeofencesRequest) {
    ListGeofencesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGeofencesRequest || (ListGeofencesRequest = {}));
export var ListGeofenceResponseEntry;
(function (ListGeofenceResponseEntry) {
    ListGeofenceResponseEntry.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Geometry && { Geometry: GeofenceGeometry.filterSensitiveLog(obj.Geometry) }))); };
})(ListGeofenceResponseEntry || (ListGeofenceResponseEntry = {}));
export var ListGeofencesResponse;
(function (ListGeofencesResponse) {
    ListGeofencesResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Entries && { Entries: obj.Entries.map(function (item) { return ListGeofenceResponseEntry.filterSensitiveLog(item); }) }))); };
})(ListGeofencesResponse || (ListGeofencesResponse = {}));
export var PutGeofenceRequest;
(function (PutGeofenceRequest) {
    PutGeofenceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Geometry && { Geometry: GeofenceGeometry.filterSensitiveLog(obj.Geometry) }))); };
})(PutGeofenceRequest || (PutGeofenceRequest = {}));
export var PutGeofenceResponse;
(function (PutGeofenceResponse) {
    PutGeofenceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutGeofenceResponse || (PutGeofenceResponse = {}));
export var UpdateGeofenceCollectionRequest;
(function (UpdateGeofenceCollectionRequest) {
    UpdateGeofenceCollectionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGeofenceCollectionRequest || (UpdateGeofenceCollectionRequest = {}));
export var UpdateGeofenceCollectionResponse;
(function (UpdateGeofenceCollectionResponse) {
    UpdateGeofenceCollectionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGeofenceCollectionResponse || (UpdateGeofenceCollectionResponse = {}));
export var GetDevicePositionRequest;
(function (GetDevicePositionRequest) {
    GetDevicePositionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetDevicePositionRequest || (GetDevicePositionRequest = {}));
export var GetDevicePositionResponse;
(function (GetDevicePositionResponse) {
    GetDevicePositionResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Position && { Position: SENSITIVE_STRING })), (obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }))); };
})(GetDevicePositionResponse || (GetDevicePositionResponse = {}));
export var GetDevicePositionHistoryRequest;
(function (GetDevicePositionHistoryRequest) {
    GetDevicePositionHistoryRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetDevicePositionHistoryRequest || (GetDevicePositionHistoryRequest = {}));
export var GetDevicePositionHistoryResponse;
(function (GetDevicePositionHistoryResponse) {
    GetDevicePositionHistoryResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.DevicePositions && {
        DevicePositions: obj.DevicePositions.map(function (item) { return DevicePosition.filterSensitiveLog(item); }),
    }))); };
})(GetDevicePositionHistoryResponse || (GetDevicePositionHistoryResponse = {}));
export var GetMapGlyphsRequest;
(function (GetMapGlyphsRequest) {
    GetMapGlyphsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapGlyphsRequest || (GetMapGlyphsRequest = {}));
export var GetMapGlyphsResponse;
(function (GetMapGlyphsResponse) {
    GetMapGlyphsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapGlyphsResponse || (GetMapGlyphsResponse = {}));
export var GetMapSpritesRequest;
(function (GetMapSpritesRequest) {
    GetMapSpritesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapSpritesRequest || (GetMapSpritesRequest = {}));
export var GetMapSpritesResponse;
(function (GetMapSpritesResponse) {
    GetMapSpritesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapSpritesResponse || (GetMapSpritesResponse = {}));
export var GetMapStyleDescriptorRequest;
(function (GetMapStyleDescriptorRequest) {
    GetMapStyleDescriptorRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapStyleDescriptorRequest || (GetMapStyleDescriptorRequest = {}));
export var GetMapStyleDescriptorResponse;
(function (GetMapStyleDescriptorResponse) {
    GetMapStyleDescriptorResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapStyleDescriptorResponse || (GetMapStyleDescriptorResponse = {}));
export var GetMapTileRequest;
(function (GetMapTileRequest) {
    GetMapTileRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapTileRequest || (GetMapTileRequest = {}));
export var GetMapTileResponse;
(function (GetMapTileResponse) {
    GetMapTileResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetMapTileResponse || (GetMapTileResponse = {}));
export var ListDevicePositionsRequest;
(function (ListDevicePositionsRequest) {
    ListDevicePositionsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListDevicePositionsRequest || (ListDevicePositionsRequest = {}));
export var ListDevicePositionsResponseEntry;
(function (ListDevicePositionsResponseEntry) {
    ListDevicePositionsResponseEntry.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Position && { Position: SENSITIVE_STRING })), (obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }))); };
})(ListDevicePositionsResponseEntry || (ListDevicePositionsResponseEntry = {}));
export var ListDevicePositionsResponse;
(function (ListDevicePositionsResponse) {
    ListDevicePositionsResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Entries && {
        Entries: obj.Entries.map(function (item) { return ListDevicePositionsResponseEntry.filterSensitiveLog(item); }),
    }))); };
})(ListDevicePositionsResponse || (ListDevicePositionsResponse = {}));
export var ListMapsRequest;
(function (ListMapsRequest) {
    ListMapsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListMapsRequest || (ListMapsRequest = {}));
export var ListMapsResponseEntry;
(function (ListMapsResponseEntry) {
    ListMapsResponseEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListMapsResponseEntry || (ListMapsResponseEntry = {}));
export var ListMapsResponse;
(function (ListMapsResponse) {
    ListMapsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListMapsResponse || (ListMapsResponse = {}));
export var ListPlaceIndexesRequest;
(function (ListPlaceIndexesRequest) {
    ListPlaceIndexesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListPlaceIndexesRequest || (ListPlaceIndexesRequest = {}));
export var ListPlaceIndexesResponseEntry;
(function (ListPlaceIndexesResponseEntry) {
    ListPlaceIndexesResponseEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListPlaceIndexesResponseEntry || (ListPlaceIndexesResponseEntry = {}));
export var ListPlaceIndexesResponse;
(function (ListPlaceIndexesResponse) {
    ListPlaceIndexesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListPlaceIndexesResponse || (ListPlaceIndexesResponse = {}));
export var ListRouteCalculatorsRequest;
(function (ListRouteCalculatorsRequest) {
    ListRouteCalculatorsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListRouteCalculatorsRequest || (ListRouteCalculatorsRequest = {}));
export var ListRouteCalculatorsResponseEntry;
(function (ListRouteCalculatorsResponseEntry) {
    ListRouteCalculatorsResponseEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListRouteCalculatorsResponseEntry || (ListRouteCalculatorsResponseEntry = {}));
export var ListRouteCalculatorsResponse;
(function (ListRouteCalculatorsResponse) {
    ListRouteCalculatorsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListRouteCalculatorsResponse || (ListRouteCalculatorsResponse = {}));
export var ListTrackerConsumersRequest;
(function (ListTrackerConsumersRequest) {
    ListTrackerConsumersRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTrackerConsumersRequest || (ListTrackerConsumersRequest = {}));
export var ListTrackerConsumersResponse;
(function (ListTrackerConsumersResponse) {
    ListTrackerConsumersResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTrackerConsumersResponse || (ListTrackerConsumersResponse = {}));
export var ListTrackersRequest;
(function (ListTrackersRequest) {
    ListTrackersRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTrackersRequest || (ListTrackersRequest = {}));
export var ListTrackersResponseEntry;
(function (ListTrackersResponseEntry) {
    ListTrackersResponseEntry.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTrackersResponseEntry || (ListTrackersResponseEntry = {}));
export var ListTrackersResponse;
(function (ListTrackersResponse) {
    ListTrackersResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTrackersResponse || (ListTrackersResponse = {}));
export var UpdateMapRequest;
(function (UpdateMapRequest) {
    UpdateMapRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateMapRequest || (UpdateMapRequest = {}));
export var UpdateMapResponse;
(function (UpdateMapResponse) {
    UpdateMapResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateMapResponse || (UpdateMapResponse = {}));
export var SearchPlaceIndexForPositionRequest;
(function (SearchPlaceIndexForPositionRequest) {
    SearchPlaceIndexForPositionRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Position && { Position: SENSITIVE_STRING }))); };
})(SearchPlaceIndexForPositionRequest || (SearchPlaceIndexForPositionRequest = {}));
export var PlaceGeometry;
(function (PlaceGeometry) {
    PlaceGeometry.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Point && { Point: SENSITIVE_STRING }))); };
})(PlaceGeometry || (PlaceGeometry = {}));
export var TimeZone;
(function (TimeZone) {
    TimeZone.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TimeZone || (TimeZone = {}));
export var Place;
(function (Place) {
    Place.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Geometry && { Geometry: PlaceGeometry.filterSensitiveLog(obj.Geometry) }))); };
})(Place || (Place = {}));
export var SearchForPositionResult;
(function (SearchForPositionResult) {
    SearchForPositionResult.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Place && { Place: Place.filterSensitiveLog(obj.Place) }))); };
})(SearchForPositionResult || (SearchForPositionResult = {}));
export var SearchPlaceIndexForPositionSummary;
(function (SearchPlaceIndexForPositionSummary) {
    SearchPlaceIndexForPositionSummary.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Position && { Position: SENSITIVE_STRING }))); };
})(SearchPlaceIndexForPositionSummary || (SearchPlaceIndexForPositionSummary = {}));
export var SearchPlaceIndexForPositionResponse;
(function (SearchPlaceIndexForPositionResponse) {
    SearchPlaceIndexForPositionResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Summary && { Summary: SearchPlaceIndexForPositionSummary.filterSensitiveLog(obj.Summary) })), (obj.Results && { Results: obj.Results.map(function (item) { return SearchForPositionResult.filterSensitiveLog(item); }) }))); };
})(SearchPlaceIndexForPositionResponse || (SearchPlaceIndexForPositionResponse = {}));
export var SearchPlaceIndexForSuggestionsRequest;
(function (SearchPlaceIndexForSuggestionsRequest) {
    SearchPlaceIndexForSuggestionsRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.Text && { Text: SENSITIVE_STRING })), (obj.BiasPosition && { BiasPosition: SENSITIVE_STRING })), (obj.FilterBBox && { FilterBBox: SENSITIVE_STRING }))); };
})(SearchPlaceIndexForSuggestionsRequest || (SearchPlaceIndexForSuggestionsRequest = {}));
export var SearchForSuggestionsResult;
(function (SearchForSuggestionsResult) {
    SearchForSuggestionsResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SearchForSuggestionsResult || (SearchForSuggestionsResult = {}));
export var SearchPlaceIndexForSuggestionsSummary;
(function (SearchPlaceIndexForSuggestionsSummary) {
    SearchPlaceIndexForSuggestionsSummary.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.Text && { Text: SENSITIVE_STRING })), (obj.BiasPosition && { BiasPosition: SENSITIVE_STRING })), (obj.FilterBBox && { FilterBBox: SENSITIVE_STRING }))); };
})(SearchPlaceIndexForSuggestionsSummary || (SearchPlaceIndexForSuggestionsSummary = {}));
export var SearchPlaceIndexForSuggestionsResponse;
(function (SearchPlaceIndexForSuggestionsResponse) {
    SearchPlaceIndexForSuggestionsResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Summary && { Summary: SearchPlaceIndexForSuggestionsSummary.filterSensitiveLog(obj.Summary) }))); };
})(SearchPlaceIndexForSuggestionsResponse || (SearchPlaceIndexForSuggestionsResponse = {}));
export var SearchPlaceIndexForTextRequest;
(function (SearchPlaceIndexForTextRequest) {
    SearchPlaceIndexForTextRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.Text && { Text: SENSITIVE_STRING })), (obj.BiasPosition && { BiasPosition: SENSITIVE_STRING })), (obj.FilterBBox && { FilterBBox: SENSITIVE_STRING }))); };
})(SearchPlaceIndexForTextRequest || (SearchPlaceIndexForTextRequest = {}));
export var SearchForTextResult;
(function (SearchForTextResult) {
    SearchForTextResult.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Place && { Place: Place.filterSensitiveLog(obj.Place) }))); };
})(SearchForTextResult || (SearchForTextResult = {}));
export var SearchPlaceIndexForTextSummary;
(function (SearchPlaceIndexForTextSummary) {
    SearchPlaceIndexForTextSummary.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.Text && { Text: SENSITIVE_STRING })), (obj.BiasPosition && { BiasPosition: SENSITIVE_STRING })), (obj.FilterBBox && { FilterBBox: SENSITIVE_STRING })), (obj.ResultBBox && { ResultBBox: SENSITIVE_STRING }))); };
})(SearchPlaceIndexForTextSummary || (SearchPlaceIndexForTextSummary = {}));
export var SearchPlaceIndexForTextResponse;
(function (SearchPlaceIndexForTextResponse) {
    SearchPlaceIndexForTextResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Summary && { Summary: SearchPlaceIndexForTextSummary.filterSensitiveLog(obj.Summary) })), (obj.Results && { Results: obj.Results.map(function (item) { return SearchForTextResult.filterSensitiveLog(item); }) }))); };
})(SearchPlaceIndexForTextResponse || (SearchPlaceIndexForTextResponse = {}));
export var UpdatePlaceIndexRequest;
(function (UpdatePlaceIndexRequest) {
    UpdatePlaceIndexRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdatePlaceIndexRequest || (UpdatePlaceIndexRequest = {}));
export var UpdatePlaceIndexResponse;
(function (UpdatePlaceIndexResponse) {
    UpdatePlaceIndexResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdatePlaceIndexResponse || (UpdatePlaceIndexResponse = {}));
export var UpdateRouteCalculatorRequest;
(function (UpdateRouteCalculatorRequest) {
    UpdateRouteCalculatorRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateRouteCalculatorRequest || (UpdateRouteCalculatorRequest = {}));
export var UpdateRouteCalculatorResponse;
(function (UpdateRouteCalculatorResponse) {
    UpdateRouteCalculatorResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateRouteCalculatorResponse || (UpdateRouteCalculatorResponse = {}));
export var UpdateTrackerRequest;
(function (UpdateTrackerRequest) {
    UpdateTrackerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTrackerRequest || (UpdateTrackerRequest = {}));
export var UpdateTrackerResponse;
(function (UpdateTrackerResponse) {
    UpdateTrackerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTrackerResponse || (UpdateTrackerResponse = {}));
