import { __assign, __awaiter, __generator, __read } from "tslib";
import { HttpRequest as __HttpRequest, isValidHostname as __isValidHostname, } from "@aws-sdk/protocol-http";
import { expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectNonNull as __expectNonNull, expectObject as __expectObject, expectString as __expectString, extendedEncodeURIComponent as __extendedEncodeURIComponent, limitedParseDouble as __limitedParseDouble, parseRfc3339DateTime as __parseRfc3339DateTime, serializeFloat as __serializeFloat, } from "@aws-sdk/smithy-client";
export var serializeAws_restJson1AssociateTrackerConsumerCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/consumers";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                body = JSON.stringify(__assign({}, (input.ConsumerArn !== undefined && input.ConsumerArn !== null && { ConsumerArn: input.ConsumerArn })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1BatchDeleteDevicePositionHistoryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/delete-positions";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                body = JSON.stringify(__assign({}, (input.DeviceIds !== undefined &&
                    input.DeviceIds !== null && { DeviceIds: serializeAws_restJson1DeviceIdsList(input.DeviceIds, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1BatchDeleteGeofenceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}/delete-geofences";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                body = JSON.stringify(__assign({}, (input.GeofenceIds !== undefined &&
                    input.GeofenceIds !== null && { GeofenceIds: serializeAws_restJson1IdList(input.GeofenceIds, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1BatchEvaluateGeofencesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}/positions";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                body = JSON.stringify(__assign({}, (input.DevicePositionUpdates !== undefined &&
                    input.DevicePositionUpdates !== null && {
                    DevicePositionUpdates: serializeAws_restJson1DevicePositionUpdateList(input.DevicePositionUpdates, context),
                })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1BatchGetDevicePositionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/get-positions";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                body = JSON.stringify(__assign({}, (input.DeviceIds !== undefined &&
                    input.DeviceIds !== null && { DeviceIds: serializeAws_restJson1IdList(input.DeviceIds, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1BatchPutGeofenceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}/put-geofences";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                body = JSON.stringify(__assign({}, (input.Entries !== undefined &&
                    input.Entries !== null && {
                    Entries: serializeAws_restJson1BatchPutGeofenceRequestEntryList(input.Entries, context),
                })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1BatchUpdateDevicePositionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/positions";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                body = JSON.stringify(__assign({}, (input.Updates !== undefined &&
                    input.Updates !== null && { Updates: serializeAws_restJson1DevicePositionUpdateList(input.Updates, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CalculateRouteCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/routes/v0/calculators/{CalculatorName}/calculate/route";
                if (input.CalculatorName !== undefined) {
                    labelValue = input.CalculatorName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CalculatorName.");
                    }
                    resolvedPath = resolvedPath.replace("{CalculatorName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CalculatorName.");
                }
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CarModeOptions !== undefined &&
                    input.CarModeOptions !== null && {
                    CarModeOptions: serializeAws_restJson1CalculateRouteCarModeOptions(input.CarModeOptions, context),
                })), (input.DepartNow !== undefined && input.DepartNow !== null && { DepartNow: input.DepartNow })), (input.DeparturePosition !== undefined &&
                    input.DeparturePosition !== null && {
                    DeparturePosition: serializeAws_restJson1Position(input.DeparturePosition, context),
                })), (input.DepartureTime !== undefined &&
                    input.DepartureTime !== null && { DepartureTime: input.DepartureTime.toISOString().split(".")[0] + "Z" })), (input.DestinationPosition !== undefined &&
                    input.DestinationPosition !== null && {
                    DestinationPosition: serializeAws_restJson1Position(input.DestinationPosition, context),
                })), (input.DistanceUnit !== undefined && input.DistanceUnit !== null && { DistanceUnit: input.DistanceUnit })), (input.IncludeLegGeometry !== undefined &&
                    input.IncludeLegGeometry !== null && { IncludeLegGeometry: input.IncludeLegGeometry })), (input.TravelMode !== undefined && input.TravelMode !== null && { TravelMode: input.TravelMode })), (input.TruckModeOptions !== undefined &&
                    input.TruckModeOptions !== null && {
                    TruckModeOptions: serializeAws_restJson1CalculateRouteTruckModeOptions(input.TruckModeOptions, context),
                })), (input.WaypointPositions !== undefined &&
                    input.WaypointPositions !== null && {
                    WaypointPositions: serializeAws_restJson1WaypointPositionList(input.WaypointPositions, context),
                })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "routes." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CalculateRouteMatrixCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/routes/v0/calculators/{CalculatorName}/calculate/route-matrix";
                if (input.CalculatorName !== undefined) {
                    labelValue = input.CalculatorName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CalculatorName.");
                    }
                    resolvedPath = resolvedPath.replace("{CalculatorName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CalculatorName.");
                }
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CarModeOptions !== undefined &&
                    input.CarModeOptions !== null && {
                    CarModeOptions: serializeAws_restJson1CalculateRouteCarModeOptions(input.CarModeOptions, context),
                })), (input.DepartNow !== undefined && input.DepartNow !== null && { DepartNow: input.DepartNow })), (input.DeparturePositions !== undefined &&
                    input.DeparturePositions !== null && {
                    DeparturePositions: serializeAws_restJson1PositionList(input.DeparturePositions, context),
                })), (input.DepartureTime !== undefined &&
                    input.DepartureTime !== null && { DepartureTime: input.DepartureTime.toISOString().split(".")[0] + "Z" })), (input.DestinationPositions !== undefined &&
                    input.DestinationPositions !== null && {
                    DestinationPositions: serializeAws_restJson1PositionList(input.DestinationPositions, context),
                })), (input.DistanceUnit !== undefined && input.DistanceUnit !== null && { DistanceUnit: input.DistanceUnit })), (input.TravelMode !== undefined && input.TravelMode !== null && { TravelMode: input.TravelMode })), (input.TruckModeOptions !== undefined &&
                    input.TruckModeOptions !== null && {
                    TruckModeOptions: serializeAws_restJson1CalculateRouteTruckModeOptions(input.TruckModeOptions, context),
                })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "routes." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateGeofenceCollectionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/geofencing/v0/collections";
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CollectionName !== undefined &&
                    input.CollectionName !== null && { CollectionName: input.CollectionName })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.KmsKeyId !== undefined && input.KmsKeyId !== null && { KmsKeyId: input.KmsKeyId })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })), (input.PricingPlanDataSource !== undefined &&
                    input.PricingPlanDataSource !== null && { PricingPlanDataSource: input.PricingPlanDataSource })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_restJson1TagMap(input.Tags, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateMapCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/maps/v0/maps";
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign({}, (input.Configuration !== undefined &&
                    input.Configuration !== null && {
                    Configuration: serializeAws_restJson1MapConfiguration(input.Configuration, context),
                })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.MapName !== undefined && input.MapName !== null && { MapName: input.MapName })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_restJson1TagMap(input.Tags, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreatePlaceIndexCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/places/v0/indexes";
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.DataSource !== undefined && input.DataSource !== null && { DataSource: input.DataSource })), (input.DataSourceConfiguration !== undefined &&
                    input.DataSourceConfiguration !== null && {
                    DataSourceConfiguration: serializeAws_restJson1DataSourceConfiguration(input.DataSourceConfiguration, context),
                })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.IndexName !== undefined && input.IndexName !== null && { IndexName: input.IndexName })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_restJson1TagMap(input.Tags, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateRouteCalculatorCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/routes/v0/calculators";
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign({}, (input.CalculatorName !== undefined &&
                    input.CalculatorName !== null && { CalculatorName: input.CalculatorName })), (input.DataSource !== undefined && input.DataSource !== null && { DataSource: input.DataSource })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_restJson1TagMap(input.Tags, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "routes." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1CreateTrackerCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tracking/v0/trackers";
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.KmsKeyId !== undefined && input.KmsKeyId !== null && { KmsKeyId: input.KmsKeyId })), (input.PositionFiltering !== undefined &&
                    input.PositionFiltering !== null && { PositionFiltering: input.PositionFiltering })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })), (input.PricingPlanDataSource !== undefined &&
                    input.PricingPlanDataSource !== null && { PricingPlanDataSource: input.PricingPlanDataSource })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_restJson1TagMap(input.Tags, context) })), (input.TrackerName !== undefined && input.TrackerName !== null && { TrackerName: input.TrackerName })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteGeofenceCollectionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteMapCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/maps/v0/maps/{MapName}";
                if (input.MapName !== undefined) {
                    labelValue = input.MapName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: MapName.");
                    }
                    resolvedPath = resolvedPath.replace("{MapName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: MapName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeletePlaceIndexCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/places/v0/indexes/{IndexName}";
                if (input.IndexName !== undefined) {
                    labelValue = input.IndexName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: IndexName.");
                    }
                    resolvedPath = resolvedPath.replace("{IndexName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: IndexName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteRouteCalculatorCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/routes/v0/calculators/{CalculatorName}";
                if (input.CalculatorName !== undefined) {
                    labelValue = input.CalculatorName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CalculatorName.");
                    }
                    resolvedPath = resolvedPath.replace("{CalculatorName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CalculatorName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "routes." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DeleteTrackerCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tracking/v0/trackers/{TrackerName}";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DescribeGeofenceCollectionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DescribeMapCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/maps/v0/maps/{MapName}";
                if (input.MapName !== undefined) {
                    labelValue = input.MapName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: MapName.");
                    }
                    resolvedPath = resolvedPath.replace("{MapName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: MapName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DescribePlaceIndexCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/places/v0/indexes/{IndexName}";
                if (input.IndexName !== undefined) {
                    labelValue = input.IndexName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: IndexName.");
                    }
                    resolvedPath = resolvedPath.replace("{IndexName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: IndexName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DescribeRouteCalculatorCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/routes/v0/calculators/{CalculatorName}";
                if (input.CalculatorName !== undefined) {
                    labelValue = input.CalculatorName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CalculatorName.");
                    }
                    resolvedPath = resolvedPath.replace("{CalculatorName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CalculatorName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "routes." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DescribeTrackerCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tracking/v0/trackers/{TrackerName}";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1DisassociateTrackerConsumerCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/consumers/{ConsumerArn}";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                if (input.ConsumerArn !== undefined) {
                    labelValue = input.ConsumerArn;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: ConsumerArn.");
                    }
                    resolvedPath = resolvedPath.replace("{ConsumerArn}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: ConsumerArn.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetDevicePositionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/positions/latest";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                if (input.DeviceId !== undefined) {
                    labelValue = input.DeviceId;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: DeviceId.");
                    }
                    resolvedPath = resolvedPath.replace("{DeviceId}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: DeviceId.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetDevicePositionHistoryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/list-positions";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                if (input.DeviceId !== undefined) {
                    labelValue = input.DeviceId;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: DeviceId.");
                    }
                    resolvedPath = resolvedPath.replace("{DeviceId}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: DeviceId.");
                }
                body = JSON.stringify(__assign(__assign(__assign({}, (input.EndTimeExclusive !== undefined &&
                    input.EndTimeExclusive !== null && {
                    EndTimeExclusive: input.EndTimeExclusive.toISOString().split(".")[0] + "Z",
                })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.StartTimeInclusive !== undefined &&
                    input.StartTimeInclusive !== null && {
                    StartTimeInclusive: input.StartTimeInclusive.toISOString().split(".")[0] + "Z",
                })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetGeofenceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                if (input.GeofenceId !== undefined) {
                    labelValue = input.GeofenceId;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: GeofenceId.");
                    }
                    resolvedPath = resolvedPath.replace("{GeofenceId}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: GeofenceId.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetMapGlyphsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/maps/v0/maps/{MapName}/glyphs/{FontStack}/{FontUnicodeRange}";
                if (input.MapName !== undefined) {
                    labelValue = input.MapName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: MapName.");
                    }
                    resolvedPath = resolvedPath.replace("{MapName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: MapName.");
                }
                if (input.FontStack !== undefined) {
                    labelValue = input.FontStack;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: FontStack.");
                    }
                    resolvedPath = resolvedPath.replace("{FontStack}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: FontStack.");
                }
                if (input.FontUnicodeRange !== undefined) {
                    labelValue = input.FontUnicodeRange;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: FontUnicodeRange.");
                    }
                    resolvedPath = resolvedPath.replace("{FontUnicodeRange}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: FontUnicodeRange.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetMapSpritesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/maps/v0/maps/{MapName}/sprites/{FileName}";
                if (input.MapName !== undefined) {
                    labelValue = input.MapName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: MapName.");
                    }
                    resolvedPath = resolvedPath.replace("{MapName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: MapName.");
                }
                if (input.FileName !== undefined) {
                    labelValue = input.FileName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: FileName.");
                    }
                    resolvedPath = resolvedPath.replace("{FileName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: FileName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetMapStyleDescriptorCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/maps/v0/maps/{MapName}/style-descriptor";
                if (input.MapName !== undefined) {
                    labelValue = input.MapName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: MapName.");
                    }
                    resolvedPath = resolvedPath.replace("{MapName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: MapName.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1GetMapTileCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/maps/v0/maps/{MapName}/tiles/{Z}/{X}/{Y}";
                if (input.MapName !== undefined) {
                    labelValue = input.MapName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: MapName.");
                    }
                    resolvedPath = resolvedPath.replace("{MapName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: MapName.");
                }
                if (input.Z !== undefined) {
                    labelValue = input.Z;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: Z.");
                    }
                    resolvedPath = resolvedPath.replace("{Z}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: Z.");
                }
                if (input.X !== undefined) {
                    labelValue = input.X;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: X.");
                    }
                    resolvedPath = resolvedPath.replace("{X}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: X.");
                }
                if (input.Y !== undefined) {
                    labelValue = input.Y;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: Y.");
                    }
                    resolvedPath = resolvedPath.replace("{Y}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: Y.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListDevicePositionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/list-positions";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                body = JSON.stringify(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListGeofenceCollectionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/geofencing/v0/list-collections";
                body = JSON.stringify(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListGeofencesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}/list-geofences";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                body = JSON.stringify(__assign({}, (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListMapsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/maps/v0/list-maps";
                body = JSON.stringify(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListPlaceIndexesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/places/v0/list-indexes";
                body = JSON.stringify(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListRouteCalculatorsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/routes/v0/list-calculators";
                body = JSON.stringify(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "routes." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListTagsForResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tags/{ResourceArn}";
                if (input.ResourceArn !== undefined) {
                    labelValue = input.ResourceArn;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: ResourceArn.");
                    }
                    resolvedPath = resolvedPath.replace("{ResourceArn}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: ResourceArn.");
                }
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "metadata." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "GET",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListTrackerConsumersCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/tracking/v0/trackers/{TrackerName}/list-consumers";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                body = JSON.stringify(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1ListTrackersCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tracking/v0/list-trackers";
                body = JSON.stringify(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1PutGeofenceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                if (input.GeofenceId !== undefined) {
                    labelValue = input.GeofenceId;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: GeofenceId.");
                    }
                    resolvedPath = resolvedPath.replace("{GeofenceId}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: GeofenceId.");
                }
                body = JSON.stringify(__assign({}, (input.Geometry !== undefined &&
                    input.Geometry !== null && { Geometry: serializeAws_restJson1GeofenceGeometry(input.Geometry, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "PUT",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1SearchPlaceIndexForPositionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/places/v0/indexes/{IndexName}/search/position";
                if (input.IndexName !== undefined) {
                    labelValue = input.IndexName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: IndexName.");
                    }
                    resolvedPath = resolvedPath.replace("{IndexName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: IndexName.");
                }
                body = JSON.stringify(__assign(__assign(__assign({}, (input.Language !== undefined && input.Language !== null && { Language: input.Language })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.Position !== undefined &&
                    input.Position !== null && { Position: serializeAws_restJson1Position(input.Position, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1SearchPlaceIndexForSuggestionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/places/v0/indexes/{IndexName}/search/suggestions";
                if (input.IndexName !== undefined) {
                    labelValue = input.IndexName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: IndexName.");
                    }
                    resolvedPath = resolvedPath.replace("{IndexName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: IndexName.");
                }
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BiasPosition !== undefined &&
                    input.BiasPosition !== null && { BiasPosition: serializeAws_restJson1Position(input.BiasPosition, context) })), (input.FilterBBox !== undefined &&
                    input.FilterBBox !== null && { FilterBBox: serializeAws_restJson1BoundingBox(input.FilterBBox, context) })), (input.FilterCountries !== undefined &&
                    input.FilterCountries !== null && {
                    FilterCountries: serializeAws_restJson1CountryCodeList(input.FilterCountries, context),
                })), (input.Language !== undefined && input.Language !== null && { Language: input.Language })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.Text !== undefined && input.Text !== null && { Text: input.Text })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1SearchPlaceIndexForTextCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/places/v0/indexes/{IndexName}/search/text";
                if (input.IndexName !== undefined) {
                    labelValue = input.IndexName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: IndexName.");
                    }
                    resolvedPath = resolvedPath.replace("{IndexName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: IndexName.");
                }
                body = JSON.stringify(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.BiasPosition !== undefined &&
                    input.BiasPosition !== null && { BiasPosition: serializeAws_restJson1Position(input.BiasPosition, context) })), (input.FilterBBox !== undefined &&
                    input.FilterBBox !== null && { FilterBBox: serializeAws_restJson1BoundingBox(input.FilterBBox, context) })), (input.FilterCountries !== undefined &&
                    input.FilterCountries !== null && {
                    FilterCountries: serializeAws_restJson1CountryCodeList(input.FilterCountries, context),
                })), (input.Language !== undefined && input.Language !== null && { Language: input.Language })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.Text !== undefined && input.Text !== null && { Text: input.Text })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1TagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tags/{ResourceArn}";
                if (input.ResourceArn !== undefined) {
                    labelValue = input.ResourceArn;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: ResourceArn.");
                    }
                    resolvedPath = resolvedPath.replace("{ResourceArn}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: ResourceArn.");
                }
                body = JSON.stringify(__assign({}, (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_restJson1TagMap(input.Tags, context) })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "metadata." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "POST",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UntagResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, query, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {};
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tags/{ResourceArn}";
                if (input.ResourceArn !== undefined) {
                    labelValue = input.ResourceArn;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: ResourceArn.");
                    }
                    resolvedPath = resolvedPath.replace("{ResourceArn}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: ResourceArn.");
                }
                query = __assign({}, (input.TagKeys !== undefined && { tagKeys: (input.TagKeys || []).map(function (_entry) { return _entry; }) }));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "metadata." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "DELETE",
                        headers: headers,
                        path: resolvedPath,
                        query: query,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateGeofenceCollectionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") +
                    "/geofencing/v0/collections/{CollectionName}";
                if (input.CollectionName !== undefined) {
                    labelValue = input.CollectionName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CollectionName.");
                    }
                    resolvedPath = resolvedPath.replace("{CollectionName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CollectionName.");
                }
                body = JSON.stringify(__assign(__assign(__assign({}, (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })), (input.PricingPlanDataSource !== undefined &&
                    input.PricingPlanDataSource !== null && { PricingPlanDataSource: input.PricingPlanDataSource })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "geofencing." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "PATCH",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateMapCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/maps/v0/maps/{MapName}";
                if (input.MapName !== undefined) {
                    labelValue = input.MapName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: MapName.");
                    }
                    resolvedPath = resolvedPath.replace("{MapName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: MapName.");
                }
                body = JSON.stringify(__assign(__assign({}, (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "maps." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "PATCH",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdatePlaceIndexCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/places/v0/indexes/{IndexName}";
                if (input.IndexName !== undefined) {
                    labelValue = input.IndexName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: IndexName.");
                    }
                    resolvedPath = resolvedPath.replace("{IndexName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: IndexName.");
                }
                body = JSON.stringify(__assign(__assign(__assign({}, (input.DataSourceConfiguration !== undefined &&
                    input.DataSourceConfiguration !== null && {
                    DataSourceConfiguration: serializeAws_restJson1DataSourceConfiguration(input.DataSourceConfiguration, context),
                })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "places." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "PATCH",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateRouteCalculatorCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/routes/v0/calculators/{CalculatorName}";
                if (input.CalculatorName !== undefined) {
                    labelValue = input.CalculatorName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: CalculatorName.");
                    }
                    resolvedPath = resolvedPath.replace("{CalculatorName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: CalculatorName.");
                }
                body = JSON.stringify(__assign(__assign({}, (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "routes." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "PATCH",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var serializeAws_restJson1UpdateTrackerCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, headers, resolvedPath, labelValue, body, resolvedHostname;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                headers = {
                    "content-type": "application/json",
                };
                resolvedPath = "" + ((basePath === null || basePath === void 0 ? void 0 : basePath.endsWith("/")) ? basePath.slice(0, -1) : basePath || "") + "/tracking/v0/trackers/{TrackerName}";
                if (input.TrackerName !== undefined) {
                    labelValue = input.TrackerName;
                    if (labelValue.length <= 0) {
                        throw new Error("Empty value provided for input HTTP label: TrackerName.");
                    }
                    resolvedPath = resolvedPath.replace("{TrackerName}", __extendedEncodeURIComponent(labelValue));
                }
                else {
                    throw new Error("No value provided for input HTTP label: TrackerName.");
                }
                body = JSON.stringify(__assign(__assign(__assign(__assign({}, (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.PositionFiltering !== undefined &&
                    input.PositionFiltering !== null && { PositionFiltering: input.PositionFiltering })), (input.PricingPlan !== undefined && input.PricingPlan !== null && { PricingPlan: input.PricingPlan })), (input.PricingPlanDataSource !== undefined &&
                    input.PricingPlanDataSource !== null && { PricingPlanDataSource: input.PricingPlanDataSource })));
                return [4, context.endpoint()];
            case 2:
                resolvedHostname = (_c.sent()).hostname;
                if (context.disableHostPrefix !== true) {
                    resolvedHostname = "tracking." + resolvedHostname;
                    if (!__isValidHostname(resolvedHostname)) {
                        throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
                    }
                }
                return [2, new __HttpRequest({
                        protocol: protocol,
                        hostname: resolvedHostname,
                        port: port,
                        method: "PATCH",
                        headers: headers,
                        path: resolvedPath,
                        body: body,
                    })];
        }
    });
}); };
export var deserializeAws_restJson1AssociateTrackerConsumerCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1AssociateTrackerConsumerCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1AssociateTrackerConsumerCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
        switch (_l.label) {
            case 0:
                _a = [__assign({}, output)];
                _k = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.location#ConflictException": return [3, 4];
                    case "InternalServerException": return [3, 6];
                    case "com.amazonaws.location#InternalServerException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 8];
                    case "ServiceQuotaExceededException": return [3, 10];
                    case "com.amazonaws.location#ServiceQuotaExceededException": return [3, 10];
                    case "ThrottlingException": return [3, 12];
                    case "com.amazonaws.location#ThrottlingException": return [3, 12];
                    case "ValidationException": return [3, 14];
                    case "com.amazonaws.location#ValidationException": return [3, 14];
                }
                return [3, 16];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ServiceQuotaExceededExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 12:
                _h = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 14:
                _j = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 16:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _l.label = 17;
            case 17:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1BatchDeleteDevicePositionHistoryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1BatchDeleteDevicePositionHistoryCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Errors: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Errors !== undefined && data.Errors !== null) {
                    contents.Errors = deserializeAws_restJson1BatchDeleteDevicePositionHistoryErrorList(data.Errors, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1BatchDeleteDevicePositionHistoryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1BatchDeleteGeofenceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1BatchDeleteGeofenceCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Errors: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Errors !== undefined && data.Errors !== null) {
                    contents.Errors = deserializeAws_restJson1BatchDeleteGeofenceErrorList(data.Errors, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1BatchDeleteGeofenceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1BatchEvaluateGeofencesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1BatchEvaluateGeofencesCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Errors: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Errors !== undefined && data.Errors !== null) {
                    contents.Errors = deserializeAws_restJson1BatchEvaluateGeofencesErrorList(data.Errors, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1BatchEvaluateGeofencesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1BatchGetDevicePositionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1BatchGetDevicePositionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    DevicePositions: undefined,
                    Errors: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.DevicePositions !== undefined && data.DevicePositions !== null) {
                    contents.DevicePositions = deserializeAws_restJson1DevicePositionList(data.DevicePositions, context);
                }
                if (data.Errors !== undefined && data.Errors !== null) {
                    contents.Errors = deserializeAws_restJson1BatchGetDevicePositionErrorList(data.Errors, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1BatchGetDevicePositionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1BatchPutGeofenceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1BatchPutGeofenceCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Errors: undefined,
                    Successes: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Errors !== undefined && data.Errors !== null) {
                    contents.Errors = deserializeAws_restJson1BatchPutGeofenceErrorList(data.Errors, context);
                }
                if (data.Successes !== undefined && data.Successes !== null) {
                    contents.Successes = deserializeAws_restJson1BatchPutGeofenceSuccessList(data.Successes, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1BatchPutGeofenceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1BatchUpdateDevicePositionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1BatchUpdateDevicePositionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Errors: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Errors !== undefined && data.Errors !== null) {
                    contents.Errors = deserializeAws_restJson1BatchUpdateDevicePositionErrorList(data.Errors, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1BatchUpdateDevicePositionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1CalculateRouteCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CalculateRouteCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Legs: undefined,
                    Summary: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Legs !== undefined && data.Legs !== null) {
                    contents.Legs = deserializeAws_restJson1LegList(data.Legs, context);
                }
                if (data.Summary !== undefined && data.Summary !== null) {
                    contents.Summary = deserializeAws_restJson1CalculateRouteSummary(data.Summary, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1CalculateRouteCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1CalculateRouteMatrixCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CalculateRouteMatrixCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    RouteMatrix: undefined,
                    SnappedDeparturePositions: undefined,
                    SnappedDestinationPositions: undefined,
                    Summary: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.RouteMatrix !== undefined && data.RouteMatrix !== null) {
                    contents.RouteMatrix = deserializeAws_restJson1RouteMatrix(data.RouteMatrix, context);
                }
                if (data.SnappedDeparturePositions !== undefined && data.SnappedDeparturePositions !== null) {
                    contents.SnappedDeparturePositions = deserializeAws_restJson1PositionList(data.SnappedDeparturePositions, context);
                }
                if (data.SnappedDestinationPositions !== undefined && data.SnappedDestinationPositions !== null) {
                    contents.SnappedDestinationPositions = deserializeAws_restJson1PositionList(data.SnappedDestinationPositions, context);
                }
                if (data.Summary !== undefined && data.Summary !== null) {
                    contents.Summary = deserializeAws_restJson1CalculateRouteMatrixSummary(data.Summary, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1CalculateRouteMatrixCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1CreateGeofenceCollectionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateGeofenceCollectionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CollectionArn: undefined,
                    CollectionName: undefined,
                    CreateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CollectionArn !== undefined && data.CollectionArn !== null) {
                    contents.CollectionArn = __expectString(data.CollectionArn);
                }
                if (data.CollectionName !== undefined && data.CollectionName !== null) {
                    contents.CollectionName = __expectString(data.CollectionName);
                }
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1CreateGeofenceCollectionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.location#ConflictException": return [3, 4];
                    case "InternalServerException": return [3, 6];
                    case "com.amazonaws.location#InternalServerException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1CreateMapCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateMapCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CreateTime: undefined,
                    MapArn: undefined,
                    MapName: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.MapArn !== undefined && data.MapArn !== null) {
                    contents.MapArn = __expectString(data.MapArn);
                }
                if (data.MapName !== undefined && data.MapName !== null) {
                    contents.MapName = __expectString(data.MapName);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1CreateMapCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.location#ConflictException": return [3, 4];
                    case "InternalServerException": return [3, 6];
                    case "com.amazonaws.location#InternalServerException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1CreatePlaceIndexCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreatePlaceIndexCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CreateTime: undefined,
                    IndexArn: undefined,
                    IndexName: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.IndexArn !== undefined && data.IndexArn !== null) {
                    contents.IndexArn = __expectString(data.IndexArn);
                }
                if (data.IndexName !== undefined && data.IndexName !== null) {
                    contents.IndexName = __expectString(data.IndexName);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1CreatePlaceIndexCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.location#ConflictException": return [3, 4];
                    case "InternalServerException": return [3, 6];
                    case "com.amazonaws.location#InternalServerException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1CreateRouteCalculatorCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateRouteCalculatorCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CalculatorArn: undefined,
                    CalculatorName: undefined,
                    CreateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CalculatorArn !== undefined && data.CalculatorArn !== null) {
                    contents.CalculatorArn = __expectString(data.CalculatorArn);
                }
                if (data.CalculatorName !== undefined && data.CalculatorName !== null) {
                    contents.CalculatorName = __expectString(data.CalculatorName);
                }
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1CreateRouteCalculatorCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.location#ConflictException": return [3, 4];
                    case "InternalServerException": return [3, 6];
                    case "com.amazonaws.location#InternalServerException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1CreateTrackerCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1CreateTrackerCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CreateTime: undefined,
                    TrackerArn: undefined,
                    TrackerName: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.TrackerArn !== undefined && data.TrackerArn !== null) {
                    contents.TrackerArn = __expectString(data.TrackerArn);
                }
                if (data.TrackerName !== undefined && data.TrackerName !== null) {
                    contents.TrackerName = __expectString(data.TrackerName);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1CreateTrackerCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.location#ConflictException": return [3, 4];
                    case "InternalServerException": return [3, 6];
                    case "com.amazonaws.location#InternalServerException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DeleteGeofenceCollectionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteGeofenceCollectionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DeleteGeofenceCollectionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DeleteMapCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteMapCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DeleteMapCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DeletePlaceIndexCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeletePlaceIndexCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DeletePlaceIndexCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DeleteRouteCalculatorCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteRouteCalculatorCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DeleteRouteCalculatorCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DeleteTrackerCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DeleteTrackerCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DeleteTrackerCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DescribeGeofenceCollectionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DescribeGeofenceCollectionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CollectionArn: undefined,
                    CollectionName: undefined,
                    CreateTime: undefined,
                    Description: undefined,
                    KmsKeyId: undefined,
                    PricingPlan: undefined,
                    PricingPlanDataSource: undefined,
                    Tags: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CollectionArn !== undefined && data.CollectionArn !== null) {
                    contents.CollectionArn = __expectString(data.CollectionArn);
                }
                if (data.CollectionName !== undefined && data.CollectionName !== null) {
                    contents.CollectionName = __expectString(data.CollectionName);
                }
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.Description !== undefined && data.Description !== null) {
                    contents.Description = __expectString(data.Description);
                }
                if (data.KmsKeyId !== undefined && data.KmsKeyId !== null) {
                    contents.KmsKeyId = __expectString(data.KmsKeyId);
                }
                if (data.PricingPlan !== undefined && data.PricingPlan !== null) {
                    contents.PricingPlan = __expectString(data.PricingPlan);
                }
                if (data.PricingPlanDataSource !== undefined && data.PricingPlanDataSource !== null) {
                    contents.PricingPlanDataSource = __expectString(data.PricingPlanDataSource);
                }
                if (data.Tags !== undefined && data.Tags !== null) {
                    contents.Tags = deserializeAws_restJson1TagMap(data.Tags, context);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DescribeGeofenceCollectionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DescribeMapCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DescribeMapCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Configuration: undefined,
                    CreateTime: undefined,
                    DataSource: undefined,
                    Description: undefined,
                    MapArn: undefined,
                    MapName: undefined,
                    PricingPlan: undefined,
                    Tags: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Configuration !== undefined && data.Configuration !== null) {
                    contents.Configuration = deserializeAws_restJson1MapConfiguration(data.Configuration, context);
                }
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.DataSource !== undefined && data.DataSource !== null) {
                    contents.DataSource = __expectString(data.DataSource);
                }
                if (data.Description !== undefined && data.Description !== null) {
                    contents.Description = __expectString(data.Description);
                }
                if (data.MapArn !== undefined && data.MapArn !== null) {
                    contents.MapArn = __expectString(data.MapArn);
                }
                if (data.MapName !== undefined && data.MapName !== null) {
                    contents.MapName = __expectString(data.MapName);
                }
                if (data.PricingPlan !== undefined && data.PricingPlan !== null) {
                    contents.PricingPlan = __expectString(data.PricingPlan);
                }
                if (data.Tags !== undefined && data.Tags !== null) {
                    contents.Tags = deserializeAws_restJson1TagMap(data.Tags, context);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DescribeMapCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DescribePlaceIndexCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DescribePlaceIndexCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CreateTime: undefined,
                    DataSource: undefined,
                    DataSourceConfiguration: undefined,
                    Description: undefined,
                    IndexArn: undefined,
                    IndexName: undefined,
                    PricingPlan: undefined,
                    Tags: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.DataSource !== undefined && data.DataSource !== null) {
                    contents.DataSource = __expectString(data.DataSource);
                }
                if (data.DataSourceConfiguration !== undefined && data.DataSourceConfiguration !== null) {
                    contents.DataSourceConfiguration = deserializeAws_restJson1DataSourceConfiguration(data.DataSourceConfiguration, context);
                }
                if (data.Description !== undefined && data.Description !== null) {
                    contents.Description = __expectString(data.Description);
                }
                if (data.IndexArn !== undefined && data.IndexArn !== null) {
                    contents.IndexArn = __expectString(data.IndexArn);
                }
                if (data.IndexName !== undefined && data.IndexName !== null) {
                    contents.IndexName = __expectString(data.IndexName);
                }
                if (data.PricingPlan !== undefined && data.PricingPlan !== null) {
                    contents.PricingPlan = __expectString(data.PricingPlan);
                }
                if (data.Tags !== undefined && data.Tags !== null) {
                    contents.Tags = deserializeAws_restJson1TagMap(data.Tags, context);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DescribePlaceIndexCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DescribeRouteCalculatorCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DescribeRouteCalculatorCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CalculatorArn: undefined,
                    CalculatorName: undefined,
                    CreateTime: undefined,
                    DataSource: undefined,
                    Description: undefined,
                    PricingPlan: undefined,
                    Tags: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CalculatorArn !== undefined && data.CalculatorArn !== null) {
                    contents.CalculatorArn = __expectString(data.CalculatorArn);
                }
                if (data.CalculatorName !== undefined && data.CalculatorName !== null) {
                    contents.CalculatorName = __expectString(data.CalculatorName);
                }
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.DataSource !== undefined && data.DataSource !== null) {
                    contents.DataSource = __expectString(data.DataSource);
                }
                if (data.Description !== undefined && data.Description !== null) {
                    contents.Description = __expectString(data.Description);
                }
                if (data.PricingPlan !== undefined && data.PricingPlan !== null) {
                    contents.PricingPlan = __expectString(data.PricingPlan);
                }
                if (data.Tags !== undefined && data.Tags !== null) {
                    contents.Tags = deserializeAws_restJson1TagMap(data.Tags, context);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DescribeRouteCalculatorCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DescribeTrackerCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DescribeTrackerCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CreateTime: undefined,
                    Description: undefined,
                    KmsKeyId: undefined,
                    PositionFiltering: undefined,
                    PricingPlan: undefined,
                    PricingPlanDataSource: undefined,
                    Tags: undefined,
                    TrackerArn: undefined,
                    TrackerName: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.Description !== undefined && data.Description !== null) {
                    contents.Description = __expectString(data.Description);
                }
                if (data.KmsKeyId !== undefined && data.KmsKeyId !== null) {
                    contents.KmsKeyId = __expectString(data.KmsKeyId);
                }
                if (data.PositionFiltering !== undefined && data.PositionFiltering !== null) {
                    contents.PositionFiltering = __expectString(data.PositionFiltering);
                }
                if (data.PricingPlan !== undefined && data.PricingPlan !== null) {
                    contents.PricingPlan = __expectString(data.PricingPlan);
                }
                if (data.PricingPlanDataSource !== undefined && data.PricingPlanDataSource !== null) {
                    contents.PricingPlanDataSource = __expectString(data.PricingPlanDataSource);
                }
                if (data.Tags !== undefined && data.Tags !== null) {
                    contents.Tags = deserializeAws_restJson1TagMap(data.Tags, context);
                }
                if (data.TrackerArn !== undefined && data.TrackerArn !== null) {
                    contents.TrackerArn = __expectString(data.TrackerArn);
                }
                if (data.TrackerName !== undefined && data.TrackerName !== null) {
                    contents.TrackerName = __expectString(data.TrackerName);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DescribeTrackerCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1DisassociateTrackerConsumerCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1DisassociateTrackerConsumerCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1DisassociateTrackerConsumerCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1GetDevicePositionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetDevicePositionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Accuracy: undefined,
                    DeviceId: undefined,
                    Position: undefined,
                    PositionProperties: undefined,
                    ReceivedTime: undefined,
                    SampleTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Accuracy !== undefined && data.Accuracy !== null) {
                    contents.Accuracy = deserializeAws_restJson1PositionalAccuracy(data.Accuracy, context);
                }
                if (data.DeviceId !== undefined && data.DeviceId !== null) {
                    contents.DeviceId = __expectString(data.DeviceId);
                }
                if (data.Position !== undefined && data.Position !== null) {
                    contents.Position = deserializeAws_restJson1Position(data.Position, context);
                }
                if (data.PositionProperties !== undefined && data.PositionProperties !== null) {
                    contents.PositionProperties = deserializeAws_restJson1PropertyMap(data.PositionProperties, context);
                }
                if (data.ReceivedTime !== undefined && data.ReceivedTime !== null) {
                    contents.ReceivedTime = __expectNonNull(__parseRfc3339DateTime(data.ReceivedTime));
                }
                if (data.SampleTime !== undefined && data.SampleTime !== null) {
                    contents.SampleTime = __expectNonNull(__parseRfc3339DateTime(data.SampleTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1GetDevicePositionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1GetDevicePositionHistoryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetDevicePositionHistoryCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    DevicePositions: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.DevicePositions !== undefined && data.DevicePositions !== null) {
                    contents.DevicePositions = deserializeAws_restJson1DevicePositionList(data.DevicePositions, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1GetDevicePositionHistoryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1GetGeofenceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetGeofenceCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CreateTime: undefined,
                    GeofenceId: undefined,
                    Geometry: undefined,
                    Status: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.GeofenceId !== undefined && data.GeofenceId !== null) {
                    contents.GeofenceId = __expectString(data.GeofenceId);
                }
                if (data.Geometry !== undefined && data.Geometry !== null) {
                    contents.Geometry = deserializeAws_restJson1GeofenceGeometry(data.Geometry, context);
                }
                if (data.Status !== undefined && data.Status !== null) {
                    contents.Status = __expectString(data.Status);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1GetGeofenceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1GetMapGlyphsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetMapGlyphsCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Blob: undefined,
                    ContentType: undefined,
                };
                if (output.headers["content-type"] !== undefined) {
                    contents.ContentType = output.headers["content-type"];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents.Blob = data;
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1GetMapGlyphsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1GetMapSpritesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetMapSpritesCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Blob: undefined,
                    ContentType: undefined,
                };
                if (output.headers["content-type"] !== undefined) {
                    contents.ContentType = output.headers["content-type"];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents.Blob = data;
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1GetMapSpritesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1GetMapStyleDescriptorCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetMapStyleDescriptorCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Blob: undefined,
                    ContentType: undefined,
                };
                if (output.headers["content-type"] !== undefined) {
                    contents.ContentType = output.headers["content-type"];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents.Blob = data;
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1GetMapStyleDescriptorCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1GetMapTileCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1GetMapTileCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Blob: undefined,
                    ContentType: undefined,
                };
                if (output.headers["content-type"] !== undefined) {
                    contents.ContentType = output.headers["content-type"];
                }
                return [4, collectBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents.Blob = data;
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1GetMapTileCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListDevicePositionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListDevicePositionsCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Entries: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Entries !== undefined && data.Entries !== null) {
                    contents.Entries = deserializeAws_restJson1ListDevicePositionsResponseEntryList(data.Entries, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListDevicePositionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ThrottlingException": return [3, 6];
                    case "com.amazonaws.location#ThrottlingException": return [3, 6];
                    case "ValidationException": return [3, 8];
                    case "com.amazonaws.location#ValidationException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListGeofenceCollectionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListGeofenceCollectionsCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Entries: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Entries !== undefined && data.Entries !== null) {
                    contents.Entries = deserializeAws_restJson1ListGeofenceCollectionsResponseEntryList(data.Entries, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListGeofenceCollectionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ThrottlingException": return [3, 6];
                    case "com.amazonaws.location#ThrottlingException": return [3, 6];
                    case "ValidationException": return [3, 8];
                    case "com.amazonaws.location#ValidationException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListGeofencesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListGeofencesCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Entries: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Entries !== undefined && data.Entries !== null) {
                    contents.Entries = deserializeAws_restJson1ListGeofenceResponseEntryList(data.Entries, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListGeofencesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListMapsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListMapsCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Entries: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Entries !== undefined && data.Entries !== null) {
                    contents.Entries = deserializeAws_restJson1ListMapsResponseEntryList(data.Entries, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListMapsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ThrottlingException": return [3, 6];
                    case "com.amazonaws.location#ThrottlingException": return [3, 6];
                    case "ValidationException": return [3, 8];
                    case "com.amazonaws.location#ValidationException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListPlaceIndexesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListPlaceIndexesCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Entries: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Entries !== undefined && data.Entries !== null) {
                    contents.Entries = deserializeAws_restJson1ListPlaceIndexesResponseEntryList(data.Entries, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListPlaceIndexesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ThrottlingException": return [3, 6];
                    case "com.amazonaws.location#ThrottlingException": return [3, 6];
                    case "ValidationException": return [3, 8];
                    case "com.amazonaws.location#ValidationException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListRouteCalculatorsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListRouteCalculatorsCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Entries: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Entries !== undefined && data.Entries !== null) {
                    contents.Entries = deserializeAws_restJson1ListRouteCalculatorsResponseEntryList(data.Entries, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListRouteCalculatorsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ThrottlingException": return [3, 6];
                    case "com.amazonaws.location#ThrottlingException": return [3, 6];
                    case "ValidationException": return [3, 8];
                    case "com.amazonaws.location#ValidationException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListTagsForResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListTagsForResourceCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Tags: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Tags !== undefined && data.Tags !== null) {
                    contents.Tags = deserializeAws_restJson1TagMap(data.Tags, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListTagsForResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListTrackerConsumersCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListTrackerConsumersCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    ConsumerArns: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.ConsumerArns !== undefined && data.ConsumerArns !== null) {
                    contents.ConsumerArns = deserializeAws_restJson1ArnList(data.ConsumerArns, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListTrackerConsumersCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1ListTrackersCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1ListTrackersCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Entries: undefined,
                    NextToken: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Entries !== undefined && data.Entries !== null) {
                    contents.Entries = deserializeAws_restJson1ListTrackersResponseEntryList(data.Entries, context);
                }
                if (data.NextToken !== undefined && data.NextToken !== null) {
                    contents.NextToken = __expectString(data.NextToken);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1ListTrackersCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ThrottlingException": return [3, 6];
                    case "com.amazonaws.location#ThrottlingException": return [3, 6];
                    case "ValidationException": return [3, 8];
                    case "com.amazonaws.location#ValidationException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1PutGeofenceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1PutGeofenceCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CreateTime: undefined,
                    GeofenceId: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CreateTime !== undefined && data.CreateTime !== null) {
                    contents.CreateTime = __expectNonNull(__parseRfc3339DateTime(data.CreateTime));
                }
                if (data.GeofenceId !== undefined && data.GeofenceId !== null) {
                    contents.GeofenceId = __expectString(data.GeofenceId);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1PutGeofenceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                _a = [__assign({}, output)];
                _j = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "ConflictException": return [3, 4];
                    case "com.amazonaws.location#ConflictException": return [3, 4];
                    case "InternalServerException": return [3, 6];
                    case "com.amazonaws.location#InternalServerException": return [3, 6];
                    case "ResourceNotFoundException": return [3, 8];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 8];
                    case "ThrottlingException": return [3, 10];
                    case "com.amazonaws.location#ThrottlingException": return [3, 10];
                    case "ValidationException": return [3, 12];
                    case "com.amazonaws.location#ValidationException": return [3, 12];
                }
                return [3, 14];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 12:
                _h = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 14:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _k.label = 15;
            case 15:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1SearchPlaceIndexForPositionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1SearchPlaceIndexForPositionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Results: undefined,
                    Summary: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Results !== undefined && data.Results !== null) {
                    contents.Results = deserializeAws_restJson1SearchForPositionResultList(data.Results, context);
                }
                if (data.Summary !== undefined && data.Summary !== null) {
                    contents.Summary = deserializeAws_restJson1SearchPlaceIndexForPositionSummary(data.Summary, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1SearchPlaceIndexForPositionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1SearchPlaceIndexForSuggestionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1SearchPlaceIndexForSuggestionsCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Results: undefined,
                    Summary: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Results !== undefined && data.Results !== null) {
                    contents.Results = deserializeAws_restJson1SearchForSuggestionsResultList(data.Results, context);
                }
                if (data.Summary !== undefined && data.Summary !== null) {
                    contents.Summary = deserializeAws_restJson1SearchPlaceIndexForSuggestionsSummary(data.Summary, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1SearchPlaceIndexForSuggestionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1SearchPlaceIndexForTextCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1SearchPlaceIndexForTextCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    Results: undefined,
                    Summary: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.Results !== undefined && data.Results !== null) {
                    contents.Results = deserializeAws_restJson1SearchForTextResultList(data.Results, context);
                }
                if (data.Summary !== undefined && data.Summary !== null) {
                    contents.Summary = deserializeAws_restJson1SearchPlaceIndexForTextSummary(data.Summary, context);
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1SearchPlaceIndexForTextCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1TagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1TagResourceCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1TagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1UntagResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UntagResourceCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                };
                return [4, collectBody(output.body, context)];
            case 1:
                _a.sent();
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1UntagResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1UpdateGeofenceCollectionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateGeofenceCollectionCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CollectionArn: undefined,
                    CollectionName: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CollectionArn !== undefined && data.CollectionArn !== null) {
                    contents.CollectionArn = __expectString(data.CollectionArn);
                }
                if (data.CollectionName !== undefined && data.CollectionName !== null) {
                    contents.CollectionName = __expectString(data.CollectionName);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1UpdateGeofenceCollectionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1UpdateMapCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateMapCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    MapArn: undefined,
                    MapName: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.MapArn !== undefined && data.MapArn !== null) {
                    contents.MapArn = __expectString(data.MapArn);
                }
                if (data.MapName !== undefined && data.MapName !== null) {
                    contents.MapName = __expectString(data.MapName);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1UpdateMapCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1UpdatePlaceIndexCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdatePlaceIndexCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    IndexArn: undefined,
                    IndexName: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.IndexArn !== undefined && data.IndexArn !== null) {
                    contents.IndexArn = __expectString(data.IndexArn);
                }
                if (data.IndexName !== undefined && data.IndexName !== null) {
                    contents.IndexName = __expectString(data.IndexName);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1UpdatePlaceIndexCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1UpdateRouteCalculatorCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateRouteCalculatorCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    CalculatorArn: undefined,
                    CalculatorName: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.CalculatorArn !== undefined && data.CalculatorArn !== null) {
                    contents.CalculatorArn = __expectString(data.CalculatorArn);
                }
                if (data.CalculatorName !== undefined && data.CalculatorName !== null) {
                    contents.CalculatorName = __expectString(data.CalculatorName);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1UpdateRouteCalculatorCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_restJson1UpdateTrackerCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (output.statusCode !== 200 && output.statusCode >= 300) {
                    return [2, deserializeAws_restJson1UpdateTrackerCommandError(output, context)];
                }
                contents = {
                    $metadata: deserializeMetadata(output),
                    TrackerArn: undefined,
                    TrackerName: undefined,
                    UpdateTime: undefined,
                };
                _a = __expectNonNull;
                _b = __expectObject;
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.apply(void 0, [_b.apply(void 0, [_c.sent()]), "body"]);
                if (data.TrackerArn !== undefined && data.TrackerArn !== null) {
                    contents.TrackerArn = __expectString(data.TrackerArn);
                }
                if (data.TrackerName !== undefined && data.TrackerName !== null) {
                    contents.TrackerName = __expectString(data.TrackerName);
                }
                if (data.UpdateTime !== undefined && data.UpdateTime !== null) {
                    contents.UpdateTime = __expectNonNull(__parseRfc3339DateTime(data.UpdateTime));
                }
                return [2, Promise.resolve(contents)];
        }
    });
}); };
var deserializeAws_restJson1UpdateTrackerCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AccessDeniedException": return [3, 2];
                    case "com.amazonaws.location#AccessDeniedException": return [3, 2];
                    case "InternalServerException": return [3, 4];
                    case "com.amazonaws.location#InternalServerException": return [3, 4];
                    case "ResourceNotFoundException": return [3, 6];
                    case "com.amazonaws.location#ResourceNotFoundException": return [3, 6];
                    case "ThrottlingException": return [3, 8];
                    case "com.amazonaws.location#ThrottlingException": return [3, 8];
                    case "ValidationException": return [3, 10];
                    case "com.amazonaws.location#ValidationException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_restJson1AccessDeniedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
var deserializeAws_restJson1AccessDeniedExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        contents = {
            name: "AccessDeniedException",
            $fault: "client",
            $metadata: deserializeMetadata(parsedOutput),
            Message: undefined,
        };
        data = parsedOutput.body;
        if (data.message !== undefined && data.message !== null) {
            contents.Message = __expectString(data.message);
        }
        return [2, contents];
    });
}); };
var deserializeAws_restJson1ConflictExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        contents = {
            name: "ConflictException",
            $fault: "client",
            $metadata: deserializeMetadata(parsedOutput),
            Message: undefined,
        };
        data = parsedOutput.body;
        if (data.message !== undefined && data.message !== null) {
            contents.Message = __expectString(data.message);
        }
        return [2, contents];
    });
}); };
var deserializeAws_restJson1InternalServerExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        contents = {
            name: "InternalServerException",
            $fault: "server",
            $retryable: {},
            $metadata: deserializeMetadata(parsedOutput),
            Message: undefined,
        };
        data = parsedOutput.body;
        if (data.message !== undefined && data.message !== null) {
            contents.Message = __expectString(data.message);
        }
        return [2, contents];
    });
}); };
var deserializeAws_restJson1ResourceNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        contents = {
            name: "ResourceNotFoundException",
            $fault: "client",
            $metadata: deserializeMetadata(parsedOutput),
            Message: undefined,
        };
        data = parsedOutput.body;
        if (data.message !== undefined && data.message !== null) {
            contents.Message = __expectString(data.message);
        }
        return [2, contents];
    });
}); };
var deserializeAws_restJson1ServiceQuotaExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        contents = {
            name: "ServiceQuotaExceededException",
            $fault: "client",
            $metadata: deserializeMetadata(parsedOutput),
            Message: undefined,
        };
        data = parsedOutput.body;
        if (data.message !== undefined && data.message !== null) {
            contents.Message = __expectString(data.message);
        }
        return [2, contents];
    });
}); };
var deserializeAws_restJson1ThrottlingExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        contents = {
            name: "ThrottlingException",
            $fault: "client",
            $retryable: {},
            $metadata: deserializeMetadata(parsedOutput),
            Message: undefined,
        };
        data = parsedOutput.body;
        if (data.message !== undefined && data.message !== null) {
            contents.Message = __expectString(data.message);
        }
        return [2, contents];
    });
}); };
var deserializeAws_restJson1ValidationExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var contents, data;
    return __generator(this, function (_a) {
        contents = {
            name: "ValidationException",
            $fault: "client",
            $metadata: deserializeMetadata(parsedOutput),
            FieldList: undefined,
            Message: undefined,
            Reason: undefined,
        };
        data = parsedOutput.body;
        if (data.fieldList !== undefined && data.fieldList !== null) {
            contents.FieldList = deserializeAws_restJson1ValidationExceptionFieldList(data.fieldList, context);
        }
        if (data.message !== undefined && data.message !== null) {
            contents.Message = __expectString(data.message);
        }
        if (data.reason !== undefined && data.reason !== null) {
            contents.Reason = __expectString(data.reason);
        }
        return [2, contents];
    });
}); };
var serializeAws_restJson1BatchPutGeofenceRequestEntry = function (input, context) {
    return __assign(__assign({}, (input.GeofenceId !== undefined && input.GeofenceId !== null && { GeofenceId: input.GeofenceId })), (input.Geometry !== undefined &&
        input.Geometry !== null && { Geometry: serializeAws_restJson1GeofenceGeometry(input.Geometry, context) }));
};
var serializeAws_restJson1BatchPutGeofenceRequestEntryList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_restJson1BatchPutGeofenceRequestEntry(entry, context);
    });
};
var serializeAws_restJson1BoundingBox = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __serializeFloat(entry);
    });
};
var serializeAws_restJson1CalculateRouteCarModeOptions = function (input, context) {
    return __assign(__assign({}, (input.AvoidFerries !== undefined && input.AvoidFerries !== null && { AvoidFerries: input.AvoidFerries })), (input.AvoidTolls !== undefined && input.AvoidTolls !== null && { AvoidTolls: input.AvoidTolls }));
};
var serializeAws_restJson1CalculateRouteTruckModeOptions = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.AvoidFerries !== undefined && input.AvoidFerries !== null && { AvoidFerries: input.AvoidFerries })), (input.AvoidTolls !== undefined && input.AvoidTolls !== null && { AvoidTolls: input.AvoidTolls })), (input.Dimensions !== undefined &&
        input.Dimensions !== null && { Dimensions: serializeAws_restJson1TruckDimensions(input.Dimensions, context) })), (input.Weight !== undefined &&
        input.Weight !== null && { Weight: serializeAws_restJson1TruckWeight(input.Weight, context) }));
};
var serializeAws_restJson1CountryCodeList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_restJson1DataSourceConfiguration = function (input, context) {
    return __assign({}, (input.IntendedUse !== undefined && input.IntendedUse !== null && { IntendedUse: input.IntendedUse }));
};
var serializeAws_restJson1DeviceIdsList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_restJson1DevicePositionUpdate = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Accuracy !== undefined &&
        input.Accuracy !== null && { Accuracy: serializeAws_restJson1PositionalAccuracy(input.Accuracy, context) })), (input.DeviceId !== undefined && input.DeviceId !== null && { DeviceId: input.DeviceId })), (input.Position !== undefined &&
        input.Position !== null && { Position: serializeAws_restJson1Position(input.Position, context) })), (input.PositionProperties !== undefined &&
        input.PositionProperties !== null && {
        PositionProperties: serializeAws_restJson1PropertyMap(input.PositionProperties, context),
    })), (input.SampleTime !== undefined &&
        input.SampleTime !== null && { SampleTime: input.SampleTime.toISOString().split(".")[0] + "Z" }));
};
var serializeAws_restJson1DevicePositionUpdateList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_restJson1DevicePositionUpdate(entry, context);
    });
};
var serializeAws_restJson1GeofenceGeometry = function (input, context) {
    return __assign({}, (input.Polygon !== undefined &&
        input.Polygon !== null && { Polygon: serializeAws_restJson1LinearRings(input.Polygon, context) }));
};
var serializeAws_restJson1IdList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_restJson1LinearRing = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_restJson1Position(entry, context);
    });
};
var serializeAws_restJson1LinearRings = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_restJson1LinearRing(entry, context);
    });
};
var serializeAws_restJson1MapConfiguration = function (input, context) {
    return __assign({}, (input.Style !== undefined && input.Style !== null && { Style: input.Style }));
};
var serializeAws_restJson1Position = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __serializeFloat(entry);
    });
};
var serializeAws_restJson1PositionalAccuracy = function (input, context) {
    return __assign({}, (input.Horizontal !== undefined &&
        input.Horizontal !== null && { Horizontal: __serializeFloat(input.Horizontal) }));
};
var serializeAws_restJson1PositionList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_restJson1Position(entry, context);
    });
};
var serializeAws_restJson1PropertyMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_restJson1TagMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_restJson1TruckDimensions = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Height !== undefined && input.Height !== null && { Height: __serializeFloat(input.Height) })), (input.Length !== undefined && input.Length !== null && { Length: __serializeFloat(input.Length) })), (input.Unit !== undefined && input.Unit !== null && { Unit: input.Unit })), (input.Width !== undefined && input.Width !== null && { Width: __serializeFloat(input.Width) }));
};
var serializeAws_restJson1TruckWeight = function (input, context) {
    return __assign(__assign({}, (input.Total !== undefined && input.Total !== null && { Total: __serializeFloat(input.Total) })), (input.Unit !== undefined && input.Unit !== null && { Unit: input.Unit }));
};
var serializeAws_restJson1WaypointPositionList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_restJson1Position(entry, context);
    });
};
var deserializeAws_restJson1ArnList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_restJson1BatchDeleteDevicePositionHistoryError = function (output, context) {
    return {
        DeviceId: __expectString(output.DeviceId),
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_restJson1BatchItemError(output.Error, context)
            : undefined,
    };
};
var deserializeAws_restJson1BatchDeleteDevicePositionHistoryErrorList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1BatchDeleteDevicePositionHistoryError(entry, context);
    });
};
var deserializeAws_restJson1BatchDeleteGeofenceError = function (output, context) {
    return {
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_restJson1BatchItemError(output.Error, context)
            : undefined,
        GeofenceId: __expectString(output.GeofenceId),
    };
};
var deserializeAws_restJson1BatchDeleteGeofenceErrorList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1BatchDeleteGeofenceError(entry, context);
    });
};
var deserializeAws_restJson1BatchEvaluateGeofencesError = function (output, context) {
    return {
        DeviceId: __expectString(output.DeviceId),
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_restJson1BatchItemError(output.Error, context)
            : undefined,
        SampleTime: output.SampleTime !== undefined && output.SampleTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.SampleTime))
            : undefined,
    };
};
var deserializeAws_restJson1BatchEvaluateGeofencesErrorList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1BatchEvaluateGeofencesError(entry, context);
    });
};
var deserializeAws_restJson1BatchGetDevicePositionError = function (output, context) {
    return {
        DeviceId: __expectString(output.DeviceId),
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_restJson1BatchItemError(output.Error, context)
            : undefined,
    };
};
var deserializeAws_restJson1BatchGetDevicePositionErrorList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1BatchGetDevicePositionError(entry, context);
    });
};
var deserializeAws_restJson1BatchItemError = function (output, context) {
    return {
        Code: __expectString(output.Code),
        Message: __expectString(output.Message),
    };
};
var deserializeAws_restJson1BatchPutGeofenceError = function (output, context) {
    return {
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_restJson1BatchItemError(output.Error, context)
            : undefined,
        GeofenceId: __expectString(output.GeofenceId),
    };
};
var deserializeAws_restJson1BatchPutGeofenceErrorList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1BatchPutGeofenceError(entry, context);
    });
};
var deserializeAws_restJson1BatchPutGeofenceSuccess = function (output, context) {
    return {
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.CreateTime))
            : undefined,
        GeofenceId: __expectString(output.GeofenceId),
        UpdateTime: output.UpdateTime !== undefined && output.UpdateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.UpdateTime))
            : undefined,
    };
};
var deserializeAws_restJson1BatchPutGeofenceSuccessList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1BatchPutGeofenceSuccess(entry, context);
    });
};
var deserializeAws_restJson1BatchUpdateDevicePositionError = function (output, context) {
    return {
        DeviceId: __expectString(output.DeviceId),
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_restJson1BatchItemError(output.Error, context)
            : undefined,
        SampleTime: output.SampleTime !== undefined && output.SampleTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.SampleTime))
            : undefined,
    };
};
var deserializeAws_restJson1BatchUpdateDevicePositionErrorList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1BatchUpdateDevicePositionError(entry, context);
    });
};
var deserializeAws_restJson1BoundingBox = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __limitedParseDouble(entry);
    });
};
var deserializeAws_restJson1CalculateRouteMatrixSummary = function (output, context) {
    return {
        DataSource: __expectString(output.DataSource),
        DistanceUnit: __expectString(output.DistanceUnit),
        ErrorCount: __expectInt32(output.ErrorCount),
        RouteCount: __expectInt32(output.RouteCount),
    };
};
var deserializeAws_restJson1CalculateRouteSummary = function (output, context) {
    return {
        DataSource: __expectString(output.DataSource),
        Distance: __limitedParseDouble(output.Distance),
        DistanceUnit: __expectString(output.DistanceUnit),
        DurationSeconds: __limitedParseDouble(output.DurationSeconds),
        RouteBBox: output.RouteBBox !== undefined && output.RouteBBox !== null
            ? deserializeAws_restJson1BoundingBox(output.RouteBBox, context)
            : undefined,
    };
};
var deserializeAws_restJson1CountryCodeList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_restJson1DataSourceConfiguration = function (output, context) {
    return {
        IntendedUse: __expectString(output.IntendedUse),
    };
};
var deserializeAws_restJson1DevicePosition = function (output, context) {
    return {
        Accuracy: output.Accuracy !== undefined && output.Accuracy !== null
            ? deserializeAws_restJson1PositionalAccuracy(output.Accuracy, context)
            : undefined,
        DeviceId: __expectString(output.DeviceId),
        Position: output.Position !== undefined && output.Position !== null
            ? deserializeAws_restJson1Position(output.Position, context)
            : undefined,
        PositionProperties: output.PositionProperties !== undefined && output.PositionProperties !== null
            ? deserializeAws_restJson1PropertyMap(output.PositionProperties, context)
            : undefined,
        ReceivedTime: output.ReceivedTime !== undefined && output.ReceivedTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.ReceivedTime))
            : undefined,
        SampleTime: output.SampleTime !== undefined && output.SampleTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.SampleTime))
            : undefined,
    };
};
var deserializeAws_restJson1DevicePositionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1DevicePosition(entry, context);
    });
};
var deserializeAws_restJson1GeofenceGeometry = function (output, context) {
    return {
        Polygon: output.Polygon !== undefined && output.Polygon !== null
            ? deserializeAws_restJson1LinearRings(output.Polygon, context)
            : undefined,
    };
};
var deserializeAws_restJson1Leg = function (output, context) {
    return {
        Distance: __limitedParseDouble(output.Distance),
        DurationSeconds: __limitedParseDouble(output.DurationSeconds),
        EndPosition: output.EndPosition !== undefined && output.EndPosition !== null
            ? deserializeAws_restJson1Position(output.EndPosition, context)
            : undefined,
        Geometry: output.Geometry !== undefined && output.Geometry !== null
            ? deserializeAws_restJson1LegGeometry(output.Geometry, context)
            : undefined,
        StartPosition: output.StartPosition !== undefined && output.StartPosition !== null
            ? deserializeAws_restJson1Position(output.StartPosition, context)
            : undefined,
        Steps: output.Steps !== undefined && output.Steps !== null
            ? deserializeAws_restJson1StepList(output.Steps, context)
            : undefined,
    };
};
var deserializeAws_restJson1LegGeometry = function (output, context) {
    return {
        LineString: output.LineString !== undefined && output.LineString !== null
            ? deserializeAws_restJson1LineString(output.LineString, context)
            : undefined,
    };
};
var deserializeAws_restJson1LegList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1Leg(entry, context);
    });
};
var deserializeAws_restJson1LinearRing = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1Position(entry, context);
    });
};
var deserializeAws_restJson1LinearRings = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1LinearRing(entry, context);
    });
};
var deserializeAws_restJson1LineString = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1Position(entry, context);
    });
};
var deserializeAws_restJson1ListDevicePositionsResponseEntry = function (output, context) {
    return {
        Accuracy: output.Accuracy !== undefined && output.Accuracy !== null
            ? deserializeAws_restJson1PositionalAccuracy(output.Accuracy, context)
            : undefined,
        DeviceId: __expectString(output.DeviceId),
        Position: output.Position !== undefined && output.Position !== null
            ? deserializeAws_restJson1Position(output.Position, context)
            : undefined,
        PositionProperties: output.PositionProperties !== undefined && output.PositionProperties !== null
            ? deserializeAws_restJson1PropertyMap(output.PositionProperties, context)
            : undefined,
        SampleTime: output.SampleTime !== undefined && output.SampleTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.SampleTime))
            : undefined,
    };
};
var deserializeAws_restJson1ListDevicePositionsResponseEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ListDevicePositionsResponseEntry(entry, context);
    });
};
var deserializeAws_restJson1ListGeofenceCollectionsResponseEntry = function (output, context) {
    return {
        CollectionName: __expectString(output.CollectionName),
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.CreateTime))
            : undefined,
        Description: __expectString(output.Description),
        PricingPlan: __expectString(output.PricingPlan),
        PricingPlanDataSource: __expectString(output.PricingPlanDataSource),
        UpdateTime: output.UpdateTime !== undefined && output.UpdateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.UpdateTime))
            : undefined,
    };
};
var deserializeAws_restJson1ListGeofenceCollectionsResponseEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ListGeofenceCollectionsResponseEntry(entry, context);
    });
};
var deserializeAws_restJson1ListGeofenceResponseEntry = function (output, context) {
    return {
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.CreateTime))
            : undefined,
        GeofenceId: __expectString(output.GeofenceId),
        Geometry: output.Geometry !== undefined && output.Geometry !== null
            ? deserializeAws_restJson1GeofenceGeometry(output.Geometry, context)
            : undefined,
        Status: __expectString(output.Status),
        UpdateTime: output.UpdateTime !== undefined && output.UpdateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.UpdateTime))
            : undefined,
    };
};
var deserializeAws_restJson1ListGeofenceResponseEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ListGeofenceResponseEntry(entry, context);
    });
};
var deserializeAws_restJson1ListMapsResponseEntry = function (output, context) {
    return {
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.CreateTime))
            : undefined,
        DataSource: __expectString(output.DataSource),
        Description: __expectString(output.Description),
        MapName: __expectString(output.MapName),
        PricingPlan: __expectString(output.PricingPlan),
        UpdateTime: output.UpdateTime !== undefined && output.UpdateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.UpdateTime))
            : undefined,
    };
};
var deserializeAws_restJson1ListMapsResponseEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ListMapsResponseEntry(entry, context);
    });
};
var deserializeAws_restJson1ListPlaceIndexesResponseEntry = function (output, context) {
    return {
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.CreateTime))
            : undefined,
        DataSource: __expectString(output.DataSource),
        Description: __expectString(output.Description),
        IndexName: __expectString(output.IndexName),
        PricingPlan: __expectString(output.PricingPlan),
        UpdateTime: output.UpdateTime !== undefined && output.UpdateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.UpdateTime))
            : undefined,
    };
};
var deserializeAws_restJson1ListPlaceIndexesResponseEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ListPlaceIndexesResponseEntry(entry, context);
    });
};
var deserializeAws_restJson1ListRouteCalculatorsResponseEntry = function (output, context) {
    return {
        CalculatorName: __expectString(output.CalculatorName),
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.CreateTime))
            : undefined,
        DataSource: __expectString(output.DataSource),
        Description: __expectString(output.Description),
        PricingPlan: __expectString(output.PricingPlan),
        UpdateTime: output.UpdateTime !== undefined && output.UpdateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.UpdateTime))
            : undefined,
    };
};
var deserializeAws_restJson1ListRouteCalculatorsResponseEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ListRouteCalculatorsResponseEntry(entry, context);
    });
};
var deserializeAws_restJson1ListTrackersResponseEntry = function (output, context) {
    return {
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.CreateTime))
            : undefined,
        Description: __expectString(output.Description),
        PricingPlan: __expectString(output.PricingPlan),
        PricingPlanDataSource: __expectString(output.PricingPlanDataSource),
        TrackerName: __expectString(output.TrackerName),
        UpdateTime: output.UpdateTime !== undefined && output.UpdateTime !== null
            ? __expectNonNull(__parseRfc3339DateTime(output.UpdateTime))
            : undefined,
    };
};
var deserializeAws_restJson1ListTrackersResponseEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ListTrackersResponseEntry(entry, context);
    });
};
var deserializeAws_restJson1MapConfiguration = function (output, context) {
    return {
        Style: __expectString(output.Style),
    };
};
var deserializeAws_restJson1Place = function (output, context) {
    return {
        AddressNumber: __expectString(output.AddressNumber),
        Country: __expectString(output.Country),
        Geometry: output.Geometry !== undefined && output.Geometry !== null
            ? deserializeAws_restJson1PlaceGeometry(output.Geometry, context)
            : undefined,
        Interpolated: __expectBoolean(output.Interpolated),
        Label: __expectString(output.Label),
        Municipality: __expectString(output.Municipality),
        Neighborhood: __expectString(output.Neighborhood),
        PostalCode: __expectString(output.PostalCode),
        Region: __expectString(output.Region),
        Street: __expectString(output.Street),
        SubRegion: __expectString(output.SubRegion),
        TimeZone: output.TimeZone !== undefined && output.TimeZone !== null
            ? deserializeAws_restJson1TimeZone(output.TimeZone, context)
            : undefined,
    };
};
var deserializeAws_restJson1PlaceGeometry = function (output, context) {
    return {
        Point: output.Point !== undefined && output.Point !== null
            ? deserializeAws_restJson1Position(output.Point, context)
            : undefined,
    };
};
var deserializeAws_restJson1Position = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __limitedParseDouble(entry);
    });
};
var deserializeAws_restJson1PositionalAccuracy = function (output, context) {
    return {
        Horizontal: __limitedParseDouble(output.Horizontal),
    };
};
var deserializeAws_restJson1PositionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1Position(entry, context);
    });
};
var deserializeAws_restJson1PropertyMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_restJson1RouteMatrix = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1RouteMatrixRow(entry, context);
    });
};
var deserializeAws_restJson1RouteMatrixEntry = function (output, context) {
    return {
        Distance: __limitedParseDouble(output.Distance),
        DurationSeconds: __limitedParseDouble(output.DurationSeconds),
        Error: output.Error !== undefined && output.Error !== null
            ? deserializeAws_restJson1RouteMatrixEntryError(output.Error, context)
            : undefined,
    };
};
var deserializeAws_restJson1RouteMatrixEntryError = function (output, context) {
    return {
        Code: __expectString(output.Code),
        Message: __expectString(output.Message),
    };
};
var deserializeAws_restJson1RouteMatrixRow = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1RouteMatrixEntry(entry, context);
    });
};
var deserializeAws_restJson1SearchForPositionResult = function (output, context) {
    return {
        Distance: __limitedParseDouble(output.Distance),
        Place: output.Place !== undefined && output.Place !== null
            ? deserializeAws_restJson1Place(output.Place, context)
            : undefined,
    };
};
var deserializeAws_restJson1SearchForPositionResultList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SearchForPositionResult(entry, context);
    });
};
var deserializeAws_restJson1SearchForSuggestionsResult = function (output, context) {
    return {
        Text: __expectString(output.Text),
    };
};
var deserializeAws_restJson1SearchForSuggestionsResultList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SearchForSuggestionsResult(entry, context);
    });
};
var deserializeAws_restJson1SearchForTextResult = function (output, context) {
    return {
        Distance: __limitedParseDouble(output.Distance),
        Place: output.Place !== undefined && output.Place !== null
            ? deserializeAws_restJson1Place(output.Place, context)
            : undefined,
        Relevance: __limitedParseDouble(output.Relevance),
    };
};
var deserializeAws_restJson1SearchForTextResultList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SearchForTextResult(entry, context);
    });
};
var deserializeAws_restJson1SearchPlaceIndexForPositionSummary = function (output, context) {
    return {
        DataSource: __expectString(output.DataSource),
        Language: __expectString(output.Language),
        MaxResults: __expectInt32(output.MaxResults),
        Position: output.Position !== undefined && output.Position !== null
            ? deserializeAws_restJson1Position(output.Position, context)
            : undefined,
    };
};
var deserializeAws_restJson1SearchPlaceIndexForSuggestionsSummary = function (output, context) {
    return {
        BiasPosition: output.BiasPosition !== undefined && output.BiasPosition !== null
            ? deserializeAws_restJson1Position(output.BiasPosition, context)
            : undefined,
        DataSource: __expectString(output.DataSource),
        FilterBBox: output.FilterBBox !== undefined && output.FilterBBox !== null
            ? deserializeAws_restJson1BoundingBox(output.FilterBBox, context)
            : undefined,
        FilterCountries: output.FilterCountries !== undefined && output.FilterCountries !== null
            ? deserializeAws_restJson1CountryCodeList(output.FilterCountries, context)
            : undefined,
        Language: __expectString(output.Language),
        MaxResults: __expectInt32(output.MaxResults),
        Text: __expectString(output.Text),
    };
};
var deserializeAws_restJson1SearchPlaceIndexForTextSummary = function (output, context) {
    return {
        BiasPosition: output.BiasPosition !== undefined && output.BiasPosition !== null
            ? deserializeAws_restJson1Position(output.BiasPosition, context)
            : undefined,
        DataSource: __expectString(output.DataSource),
        FilterBBox: output.FilterBBox !== undefined && output.FilterBBox !== null
            ? deserializeAws_restJson1BoundingBox(output.FilterBBox, context)
            : undefined,
        FilterCountries: output.FilterCountries !== undefined && output.FilterCountries !== null
            ? deserializeAws_restJson1CountryCodeList(output.FilterCountries, context)
            : undefined,
        Language: __expectString(output.Language),
        MaxResults: __expectInt32(output.MaxResults),
        ResultBBox: output.ResultBBox !== undefined && output.ResultBBox !== null
            ? deserializeAws_restJson1BoundingBox(output.ResultBBox, context)
            : undefined,
        Text: __expectString(output.Text),
    };
};
var deserializeAws_restJson1Step = function (output, context) {
    return {
        Distance: __limitedParseDouble(output.Distance),
        DurationSeconds: __limitedParseDouble(output.DurationSeconds),
        EndPosition: output.EndPosition !== undefined && output.EndPosition !== null
            ? deserializeAws_restJson1Position(output.EndPosition, context)
            : undefined,
        GeometryOffset: __expectInt32(output.GeometryOffset),
        StartPosition: output.StartPosition !== undefined && output.StartPosition !== null
            ? deserializeAws_restJson1Position(output.StartPosition, context)
            : undefined,
    };
};
var deserializeAws_restJson1StepList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1Step(entry, context);
    });
};
var deserializeAws_restJson1TagMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_restJson1TimeZone = function (output, context) {
    return {
        Name: __expectString(output.Name),
        Offset: __expectInt32(output.Offset),
    };
};
var deserializeAws_restJson1ValidationExceptionField = function (output, context) {
    return {
        Message: __expectString(output.message),
        Name: __expectString(output.name),
    };
};
var deserializeAws_restJson1ValidationExceptionFieldList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ValidationExceptionField(entry, context);
    });
};
var deserializeMetadata = function (output) {
    var _a;
    return ({
        httpStatusCode: output.statusCode,
        requestId: (_a = output.headers["x-amzn-requestid"]) !== null && _a !== void 0 ? _a : output.headers["x-amzn-request-id"],
        extendedRequestId: output.headers["x-amz-id-2"],
        cfId: output.headers["x-amz-cf-id"],
    });
};
var collectBody = function (streamBody, context) {
    if (streamBody === void 0) { streamBody = new Uint8Array(); }
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
var collectBodyString = function (streamBody, context) {
    return collectBody(streamBody, context).then(function (body) { return context.utf8Encoder(body); });
};
var isSerializableHeaderValue = function (value) {
    return value !== undefined &&
        value !== null &&
        value !== "" &&
        (!Object.getOwnPropertyNames(value).includes("length") || value.length != 0) &&
        (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
};
var parseBody = function (streamBody, context) {
    return collectBodyString(streamBody, context).then(function (encoded) {
        if (encoded.length) {
            return JSON.parse(encoded);
        }
        return {};
    });
};
var loadRestJsonErrorCode = function (output, data) {
    var findKey = function (object, key) { return Object.keys(object).find(function (k) { return k.toLowerCase() === key.toLowerCase(); }); };
    var sanitizeErrorCode = function (rawValue) {
        var cleanValue = rawValue;
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    var headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
    return "";
};
